import React, { useCallback, useEffect, useRef, useState } from "react";
import useLoadScript from "./useLoadScript";

const AutoComplete = React.forwardRef(
  (
    { name, onChange, className, placeholder, type, onFocus, onSelect, onChangeInput, defaultValue = '' },
    ref,
    { key = process.env.REACT_APP_GOOGLE_MAP_KEY } = {}
  ) => {
    const isScriptLoaded = useLoadScript({ key });
    const autocompleteRef = useRef(null);
    const [place, setPlace] = useState("");
    const [inputValue, setInputValue] = useState(null)
    const [countryData, setCountryData] = useState("");

    const [accuracy, setAccuracy] = useState(false);

    useEffect(() => {
      if (isScriptLoaded && autocompleteRef && autocompleteRef.current) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          autocompleteRef.current,
          { types: ["geocode"] }
        );
        window.google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function (d) {
            const place = autocomplete.getPlace()
            const { formatted_address, address_components, types, place_id, geometry } = place
            const geometryLocation = geometry.location
            if (!geometry || !geometryLocation) {
              window.alert("No details available for input: '" + place.name + "'");
              return;
            }
            const location = { lat: geometryLocation.lat(), lng: geometryLocation.lng() }
            
            const country = address_components.find((item) =>
              item.types.includes("country")
            );

            let countryData
            if (country) {
               countryData = {
                long_name: country.long_name,
                short_name: country.short_name,
              };

              setCountryData(countryData);
            }
            onSelect && onSelect({ formatted_address, address_components, types, place_id, location, country: countryData || {}  }, place)
            setPlace(formatted_address);
            setInputValue(formatted_address)
            setAccuracy(true);
          }
        );
      }
    }, [isScriptLoaded, autocompleteRef, onSelect]);

    const onChangeLocactionInput = useCallback(event => {
      const value = event.target.value
      setInputValue(value)
      if(accuracy) setAccuracy(false);
      onChangeInput && onChangeInput (value)
    }, [onChangeInput, accuracy])

    useEffect(()=>{
      if (defaultValue && inputValue === null) setInputValue(defaultValue)
    }, [defaultValue, inputValue])

    if (!isScriptLoaded) {
      return null;
    }

    return (
      <>
        <input
          id="locationInput"
          name={name}
          type={type}
          ref={autocompleteRef}
          className={className}
          placeholder={placeholder}
          onFocus={onFocus}
          data-accurate={accuracy}
          onChange={onChangeLocactionInput}
          value={inputValue}
        />
        <input
          name={name}
          type={"hidden"}
          ref={ref}
          value={place}
          onChange={onChange}
        />
        <input
          name={"country"}
          type={"hidden"}
          ref={ref}
          value={JSON.stringify(countryData)}
        />
      </>
    );
  }
);

export default AutoComplete;
