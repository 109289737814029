import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { organizationState } from "../atoms";
import { client } from "../common/apollo/ApolloClient";
import { GET_ORGANIZATION } from "../graphqlQueries";

export default function useOrganization() {
    const [organization, setOrganization] = useRecoilState(organizationState);

    const refreshOrganization = useCallback(
      async () => {
        const orgAPI = await client.query({ query: GET_ORGANIZATION, partialRefetch: true })

        const organizationData = orgAPI?.data.getOrganization;
        setOrganization({
            ...organization,...organizationData
        });
        client.stop()
        await client.clearStore()
      },
      [organization,setOrganization],
    )

    const removeProjectFromOtherOrganization = useCallback((projectId)=>{
      const orgData = JSON.parse(JSON.stringify(organization))
      let otherOrganizationHouses = orgData?.otherOrganizationHouses || []
      otherOrganizationHouses = orgData.otherOrganizationHouses.filter(data=>!(data?.house?.id === projectId))
      setOrganization({...orgData,otherOrganizationHouses})
    }, [organization, setOrganization])
    

    return {
        organization,
        refreshOrganization,
        removeProjectFromOtherOrganization
    }
}
