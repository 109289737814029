import React, { useMemo } from 'react'
import { getImgLocation } from '../../utils'
import {
  InputTextarea,
  InputText,
  InputUrl,
  InputFile,
  InputNumber,
  Select,
  Location,
  Checkbox,
  Toggle,
  InputMultipleFiles,
  Chips
} from '../index'
import Switch from '../switch'

const FormFields = ({
  type,
  id,
  fieldName,
  options = [],
  onChange,
  extraData = {},
  validations,
  onChangeStatus,
  defaultFieldValue,
  showFieldSettings,
  defaultFieldSettings,
  fieldSettingsValue,
  onFieldDelete,
  suggestUsText,
  showValidationMessage = true,
  disabled,
  ...props
}) => {

  const formFieldName = fieldName || id

  const commonFieldProps = useMemo(() => {
    return {
      id,
      validations,
      onDelete: onFieldDelete,
      fieldName: formFieldName,
      showValidationMessage,
      showSettings: showFieldSettings,
      settingsValue: fieldSettingsValue,
      defaultSettings: defaultFieldSettings
    }
  }, [defaultFieldSettings, fieldSettingsValue, id, onFieldDelete, showFieldSettings, validations, formFieldName, showValidationMessage])

  switch (type) {
    case 'number':
      return (
        <InputNumber
          {...props}
          onChange={onChange}
          unit={extraData?.unit}
          defaultValue={defaultFieldValue}
          {...commonFieldProps}
        />
      )

    case 'file':
      const fileQuantity = validations?.maxUploadFileQuantity || 1
      return (
        !(fileQuantity > 1) ?
          <InputFile
            {...props}
            onChange={onChange}
            onChangeStatus={onChangeStatus}
            accept={validations?.supportedFileTypes || []}
            defaultValue={getImgLocation(defaultFieldValue?.[0])}
            {...commonFieldProps}
          />
          :
          <InputMultipleFiles
            {...props}
            shadow={true}
            max={fileQuantity}
            onChange={onChange}
            fixedCount={fileQuantity}
            onChangeStatus={onChangeStatus}
            accept={validations?.supportedFileTypes || []}
            defaultValue={getImgLocation(defaultFieldValue)}
            //does not supports internal settings popup functionality fully
            {...commonFieldProps}
          />
      )

    case 'text':
      return (
        <InputText
          {...props}
          onChange={onChange}
          defaultValue={defaultFieldValue}
          {...commonFieldProps}
        />
      )

    case 'url':
      return (
        <InputUrl
          {...props}
          onChange={onChange}
          defaultValue={defaultFieldValue}
          {...commonFieldProps}
        />
      )

    case 'textarea':
      return (
        <InputTextarea
          {...props}
          onChange={onChange}
          defaultValue={defaultFieldValue}
          {...commonFieldProps}
        />
      )

    case 'toggle':
      return (
        <Toggle
          {...props}
          data={options}
          onChange={onChange}
          defaultValue={defaultFieldValue}
          {...commonFieldProps}
        />
      )

    case 'checkbox':
      return (
        <Checkbox
          {...props}
          onChange={onChange}
          defaultValue={defaultFieldValue}
          {...commonFieldProps}
        />
      )

    case 'select':
    case 'multiselect':
    case 'multiSelectionWithSection':
    case 'multiSelectionWithTree':
      const selectType = (type === 'multiSelectionWithSection' ? 'category' : (type === 'multiSelectionWithTree' ? 'tree' : 'select'))
      const multiple = (type === 'multiSelectionWithTree' ? validations?.multiple : (type === 'select' ? false : true))
      return (
        <Select
          {...props}
          options={options}
          onSelect={onChange}
          multiple={multiple}
          defaultValue={defaultFieldValue}
          suggestUsText={selectType !== 'tree' ? suggestUsText : null}
          showClearAll={selectType === 'tree'}
          type={selectType}
          unSelectableBranches={validations?.unselectable || []}
          parentSelectable={validations?.parentSelectable}
          maxVisibleBranches={validations?.maxVisibleBranches}
          optionsVisibleOnSearch={validations?.optionsVisibleOnSearch}
          disable={disabled}
          {...commonFieldProps}
        />
      )

    case 'country':
    case 'location':
      return (
        <Location
          {...props}
          type={type}
          onSelect={onChange}
          defaultValue={defaultFieldValue}
          {...commonFieldProps}
        />
      )

    // NOTE: switch & chips does not support fieldName prop... so can not be properly used inside form...
    case 'switch':
      return (
        <Switch {...props}  defaultValue={defaultFieldValue} onChange={onChange}/>
      )

    case 'chips':
      return (
        <Chips {...props} chipsData={options}  defaultValue={defaultFieldValue} onSelect={onChange}/>
      )
        
    default:
      return <></>
  }
}

export default FormFields
