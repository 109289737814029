import React, { cloneElement, isValidElement, useCallback, useMemo, useState } from "react";
import TreeLevelIcon from "./tree-level-icon";
import LevelIdentifier from "./level-identifier";
import ConditionalWrapper from "../conditional-wrapper";

const TableRow = React.memo((props) => {
    const { columns, row, onRowClick, expandedRows, selectedRows, onExpansionSuccess, onExpansionFailure, openRowAsync, toggleExpandedRowStateData, config } = props;
    const [isLoading, setIsLoading] = useState(false);

    const isOpened = useMemo(() => expandedRows.has(row.id), [expandedRows, row])
    const isSelected = useMemo(() => selectedRows.has(row.id), [row, selectedRows])
    const isSelectable = useMemo(() => !row?.rowNotSelectable, [row])

    const handleRowClick = useCallback((event) => {
        if (isSelectable && onRowClick) {
            onRowClick(row, event);
        }
    }, [onRowClick, row, isSelectable]);

    const handleToggleRow = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        setIsLoading(true);

        const handleSuccess = (res) => {
            if (onExpansionSuccess) onExpansionSuccess(res, row);
        }

        const handleFailure = (error) => {
            if (onExpansionFailure) onExpansionFailure(error, row);
        }

        if (config.asyncRowExpansion && !row?.childrens?.length && !isOpened && openRowAsync) {
            //async processing
            try {
                const res = await openRowAsync(row, true);
                handleSuccess(res);
            } catch (error) {
                handleFailure(error)
                return
            }
        }
        toggleExpandedRowStateData(row.id)
        setIsLoading(false)
    }, [row, isOpened, openRowAsync, onExpansionFailure, onExpansionSuccess, config, toggleExpandedRowStateData])

    return (
        <React.Fragment key={row.id}>
            <tr
                onClick={handleRowClick}
                className={`${isSelected ? "selected" : ""} ${row.className || ""} ${!isSelectable ? "row-not-selectable" : "row-selectable"}`}
                data-key={row.id}
            >
                {columns.map((column) => <TableRowData key={`${row.id}_${column.id}`} row={row} column={column} handleToggleRow={handleToggleRow} isOpened={isOpened} isLoading={isLoading} />)}
            </tr>
            {(isOpened && Array.isArray(row.childrens)) && row.childrens.map((children) => (
                <TableRow {...props} row={children} key={children.id} />
            ))}
        </React.Fragment>
    );
});

const TableRowData = ({ row, column, handleToggleRow, isOpened, isLoading }) => {
    const isExpandable = useMemo(() => column.expandable && !row.leafNode, [row, column])
    return (
        <td className={`${column.leftFreezed ? "freezed left-freezed" : (column.rightFreezed ? "freezed right-freezed" : "")} column-id-${column.id} row-id-${row.id} ${column.className || ""}`}>
            <ConditionalWrapper condition={column.expandable} Wrapper={<DataComponentWrapperWithLevel row={row} />}>
                {isExpandable ? (<TreeLevelIcon onClick={handleToggleRow} open={isOpened} loading={isLoading} />) : (<div className="tree-expand-icon-spacer" />)}
                {isValidElement(column.render) ? cloneElement(column.render, { tableData: { column, row } }) : <>{row[column.id]}</>}
            </ConditionalWrapper>
        </td>
    )
}

const DataComponentWrapperWithLevel = ({ children, row }) => (
    <div className="items-row">
        <LevelIdentifier level={row.level} />
        {children}
    </div>
)

export default TableRow