import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Custom hook to track the position of a DOM element.
 * 
 * Using `getBoundingClientRect` method to get the current position
 * of the element `elementRef`.
 * 
 * @param elementRef - A React ref object pointing
 * to the DOM element whose position you want to track.
 * 
 * @returns {Object} An object with `top`, `left`, `width`, and `height` properties representing the
 * current position of the element relative to the viewport.
 */

function useTrackPosition(elementRef) {

    const [position, setPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
    const requestRef = useRef();
  
    const checkPosition = useCallback(() => {
      if (elementRef.current) {
        const currentPosition = elementRef.current.getBoundingClientRect();
  
        // Update state only if position changes
        setPosition(prevPosition => {
            if (currentPosition.top !== prevPosition.top || currentPosition.left !== prevPosition.left || currentPosition.width !== prevPosition.width || currentPosition.width !== prevPosition.width ) {
                return { top: currentPosition.top, left: currentPosition.left, width: currentPosition.width, height: currentPosition.height };
            }
            return prevPosition;
        });
      }
  
      // Request the next frame
      requestRef.current = requestAnimationFrame(checkPosition);
    }, [elementRef]);
  
    useEffect(() => {
      // Start the position check loop
      requestRef.current = requestAnimationFrame(checkPosition);
  
      return () => {
        // Cleaning up the animation frame on component unmount
        cancelAnimationFrame(requestRef.current);
      };
    }, [checkPosition]);

    return position
}

export default useTrackPosition