import React, { cloneElement, useCallback, useEffect, useState } from "react";
import { deepClone, generateUuid } from "../../utils";
// import LabelBox from "../label-box";

const RepeatComponent = ({ alignment = "horizontal", children, onChange, max = 10, fieldName, defaultValue, fixedCount = 0, removable = false, disableAddButton = false, onValidationStatusChange, addMoreButtonName }) => {
  const [repeatDataIds, setRepeatDataIds] = useState(Array.from(Array(fixedCount || 1), () => generateUuid()));
  const [allRepeatData, setAllRepeatData] = useState({});
  const [repeatDataDefaultValues, setRepeatDataDefaultValues] = useState({});
  const [defaultValueSetStatus, setDefaultValueSetStatus] = useState(false)
  const [errorData, setErrorData] = useState({})
  
  const onClickAddMore = useCallback(() => {
    const newUuid = generateUuid();
    setRepeatDataIds((oldData) => [...oldData, newUuid]);
  }, []);

  useEffect(() => {
    if (!defaultValueSetStatus && defaultValue && defaultValue.length && !Object.keys(allRepeatData).length) {
      setDefaultValueSetStatus(true)
      const finalCountToShow = defaultValue.length > fixedCount ? defaultValue.length : fixedCount
      const defaultIds = Array.from(Array(finalCountToShow || 1), () => generateUuid())
      const defaultData = {}
      defaultIds.forEach((id, index) => defaultData[id] = defaultValue[index])
      setAllRepeatData(defaultData)
      setRepeatDataIds(defaultIds)
      setRepeatDataDefaultValues(defaultData)
    }
  }, [defaultValue, allRepeatData, defaultValueSetStatus, fixedCount])

  const onChangeOfData = useCallback(updatedData => {
    const updatedDataValues = (Object.keys(updatedData).length) ? Object.values(updatedData) : []
    const updatedValidData = updatedDataValues.filter(fileData => !!fileData)
    const argData = fieldName ? { [fieldName]: updatedValidData } : updatedValidData
    onChange && onChange(argData)
  }, [onChange, fieldName])

  const onChangeChildData = useCallback((data, repeatDataId) => {
    let updatedData
    if (!data) {
      if (allRepeatData[repeatDataId]) delete allRepeatData[repeatDataId]
      updatedData = allRepeatData
    } else {
      updatedData = { ...allRepeatData, [repeatDataId]: data }
    }

    onChangeOfData(updatedData)
    setAllRepeatData(updatedData)
  }, [allRepeatData, onChangeOfData]);

  const onRemoveChildData = useCallback(id => {
    const index = repeatDataIds.indexOf(id)
    const updatedRepeatData = deepClone(allRepeatData)
    const errorDataClone = deepClone(errorData)
    const updatedRepeatDataIds = [...repeatDataIds]

    if ([id] in errorData) {
      delete errorDataClone[id]
      setErrorData(errorDataClone)
      const fieldshaveError = Object.values(errorDataClone).includes(false)
      onValidationStatusChange && onValidationStatusChange(!fieldshaveError)
    }

    if (index > -1) {
      //removing removed id from list
      updatedRepeatDataIds.splice(index, 1)

      //removing data of removed id from list
      if (updatedRepeatData[id]) delete updatedRepeatData[id]

      onChangeOfData(updatedRepeatData)
      setAllRepeatData(updatedRepeatData)
      setRepeatDataIds(updatedRepeatDataIds)
    }
  }, [allRepeatData, repeatDataIds, onChangeOfData, errorData, onValidationStatusChange])

  const onChildValidationChange = useCallback((id, isValid) => {
    const newErrorData = { ...errorData, [id]: isValid }
    const fieldshaveError = Object.values(newErrorData).includes(false)
    onValidationStatusChange && onValidationStatusChange(!fieldshaveError)
    setErrorData(newErrorData)
  }, [errorData, onValidationStatusChange])

  return (
    <div className={`repeatComponent ${alignment === "vertical" ? "repeatComponent-vertical" : "repeatComponent-horizontal"} ${fixedCount ? 'repeatComponent-fixed' : ''} ${(repeatDataIds.length === max || fixedCount) ? 'repeatComponent-max' : ''}`}>
      <div className="repeatComponent-container">
        <div className="repeatComponent-children-container">
          {repeatDataIds.map((repeatDataId, index) => (
            <div className="repeatComponent-children" key={repeatDataId}>
              {cloneElement(children, { onChange: (data) => onChangeChildData(data, repeatDataId), removable: (removable && repeatDataIds.length > 1), onRemove: () => onRemoveChildData(repeatDataId), defaultValue: repeatDataDefaultValues?.[repeatDataId], onValidationChange: (isValid) => onChildValidationChange(repeatDataId, isValid), returnIndex: () => { return { index: index, length: repeatDataIds.length } } })}
            </div>
          ))}
        </div>
      </div>
      <div className={`add-button ${disableAddButton ? 'add-button-disabled' : ''}`} onClick={onClickAddMore}>
        <img
          className="add-more-icon"
          src={alignment === "vertical" ? "/images/icon/plus-darkblack.svg" : "/images/icon/plus-red.svg"}
          alt="add"
        />
        {(alignment === "vertical" || addMoreButtonName) && (
          <p className="add-more-text">Add another</p>
        )}
      </div>
    </div>
  );
};

export default RepeatComponent;
