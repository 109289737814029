import React, { useMemo } from 'react'
import { SUSTAINABILITY_PILLERS } from '../../constants/sustainibilityPillers';
import Tooltip from '../tooltip';
import CicularRing from '../circular-ring';
import { COLOR } from '../../constants/color';

const SustainabilityPillars = ({ pillars, totalCount, tooltipPosition }) => {
    return (
        <div className="bimmatch-sustainability-icons-wrapper">
            {pillars.map(pillar => <SustainabilityPillarItem key={pillar.id} id={pillar.id} value={pillar.value} totalCount={totalCount} tooltipPosition={tooltipPosition} />)}
        </div>
    )
}

const SustainabilityPillarItem = ({ id, value, totalCount, tooltipPosition }) => {
    const pillarData = useMemo(() => SUSTAINABILITY_PILLERS.find((p) => p.id === id) || {}, [id]);
    const percentage = useMemo(() => (+value * 100) / +totalCount, [value, totalCount]);

    if (!percentage) {
        return <img src={pillarData.blankIcon} alt={id} className="blank-pillar-icon" />;
    }

    return (
        <Tooltip
            content={<SustainabilityPillarTooltipContent id={id} percentage={percentage} pillarData={pillarData} totalCount={totalCount} value={value} />}
            showOnHover={true}
            placement={tooltipPosition}
        >
            <SustainabilityPillarIcon id={id} pillarData={pillarData} percentage={percentage} />
        </Tooltip>
    );
}

const SustainabilityPillarTooltipContent = ({ percentage, id, pillarData, value, totalCount }) => {
    return (
        <div className="circular-sustainibility-piller-wrapper">
            <SustainabilityPillarIcon id={id} pillarData={pillarData} percentage={percentage} />
            <p>{`${value} out of ${totalCount} items are possibly compliance with ${pillarData.label} sustainability standards`}</p>
        </div>
    )
}

const SustainabilityPillarIcon = ({ id, pillarData, percentage }) => {
    return (
        <CicularRing percentage={percentage} ringColor={COLOR.LIGHT_GREEN} ringWidth="4px">
            <img src={pillarData.greenIcon} alt={id} />
        </CicularRing>
    )
}


export default SustainabilityPillars