import firebase from "firebase";
import "firebase/auth";
import * as firebaseui from "firebaseui";
import { getCookie, setCookie } from "../../utils";
import config from "./firebase.config";
import { accessTokenExpiryTime, autoLogoutTime, commonAuthDeleteCookies, commonAuthHeaders } from "../auth/utils";

function createFunction() {
  // Initialize Firebase app
  !firebase.apps.length && firebase.initializeApp(config);
  const analytics = firebase.analytics();
  // Intialize Auth
  const auth = firebase.auth();
  const providers = [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ];
  const ui = new firebaseui.auth.AuthUI(auth);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  }

  return {
    analytics,
    providers,
    auth,
    ui,
    signInWithGoogle
  };
}

// Making Single instance
let firebaseInstance = null;
export const getFirebaseInstance = function () {
  if (!firebaseInstance) {
    firebaseInstance = createFunction();
  }
  return firebaseInstance;
};

export const getFirebaseToken = () => {
  return new Promise((resolve, reject) => {
    if (!firebase?.auth?.()?.currentUser) {
      return reject();
    }
    firebase
      .auth()
      .currentUser.getIdToken(false)
      .then((token) => {
        setCookie("__bauhub_token", token, accessTokenExpiryTime);
        resolve(token);
      })
      .catch((e) => {
        window.location.reload();
        reject(e);
      });
  });
};


export const checkAndSessionTimeout = async (minutes = 60) => {
  // if (process.env.NODE_ENV === "development") {
  //   return;
  // }

  console.log("process.env.NODE_ENV", process.env.NODE_ENV);
  

  let ts = getCookie("__bauhub_token_ts")
  const isTimeToLogout = (ts && !isNaN(ts) && ((Date.now() - (+ts))) > (minutes * 60 * 1000))

  if (firebase?.auth()?.currentUser || getCookie("__bauhub_refresh_token")) {
    if (!isTimeToLogout) {
      await commonAuthHeaders()
      return
    }

    logout(true, "/signin");
  }
};

export const visibilityChangeListner = async (event) => {
  if (document.visibilityState === "visible") {
    await checkAndSessionTimeout(autoLogoutTime);
  }
};

export const onWindowBlurListener = (event) => {
  setCookie("__bauhub_token_ts", Date.now());
};

export const logout = (clearStorage = false, redirectTo) => {
  if (clearStorage) {
    localStorage.clear();
  }

  commonAuthDeleteCookies()

  if (firebase?.auth()?.currentUser) {
    firebase.auth().signOut();
  }

  if (redirectTo) {
    window.location.href = redirectTo;
  }
};
