import React, { useCallback, useState } from "react";
import Popover from "../popover";

const Accordion = ({
  children,
  title = "Accordion Title",
  open = false,
  addButtonName = 'Add properties',
  manageButtonName = 'Manage visibility',
  onClickHeaderAddButton,
  onClickHeaderManageButton,
  RightHandSideComponent,
  hideSettings=false,
  onClickAccordion,
  disable = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const onClickHead = useCallback(() => {
    const updatedStatus = !isOpen
    setIsOpen(updatedStatus);
    onClickAccordion && onClickAccordion(updatedStatus)
  }, [isOpen, onClickAccordion]);

  return (
    <div
      className={`accordion ${isOpen ? "accordion-open" : "accordion-close"} ${disable ? 'accordion-disable' : ''}`}
      {...props}
    >
      <div className="accordion-container">
        <div onClick={onClickHead} className="accordion-head">
          <div className="accordion-head-left">
            <img
              className="down-icon"
              src="/images/icon/down-arrow.svg"
              alt="add"
            />
            {typeof title === 'string' ? (<h3 className="accordion-name">{title}</h3>) : (title)}
          </div>
          {isOpen && !hideSettings && (onClickHeaderAddButton || onClickHeaderManageButton) && (
            <Popover
              className='accordion-settings-popover'
              content={
                <AccordionSettingsOptions
                  onClickAdd={onClickHeaderAddButton}
                  onClickVisibility={onClickHeaderManageButton}
                  addButtonName={addButtonName}
                  manageButtonName={manageButtonName}
                />}
              hideArrow={true}
              placement={'bottom'}
            >
              <img className='label-setting' src={'/images/icon/settings.svg'} alt='settings' />
            </Popover>
          )}
          {RightHandSideComponent && RightHandSideComponent}
        </div>
        <div className="accordion-body">{children}</div>
      </div>
    </div>
  );
};

const AccordionSettingsOptions = ({ onClickAdd, onClickVisibility, addButtonName, manageButtonName }) => {
  return (
    <div className='accordion-settings-options'>
      {onClickAdd && (
        <div className='accordion-settings-option' onClick={onClickAdd}>
          <img src={'/images/icon/plus-darkblack.svg'} alt='add' /> <span>{addButtonName}</span>
        </div>
      )}

      {onClickVisibility && (
        <div className='accordion-settings-option' onClick={onClickVisibility}>
          <img src={'/images/icon/show-eye.svg'} alt='visibility' /> <span>{manageButtonName}</span>
        </div>
      )}

    </div>
  )
}

export default Accordion;
