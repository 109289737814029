import axios from 'axios';
import { getCookie, setCookie } from '../../utils';
import { accessTokenExpiryTime } from './utils';

// env
const { REACT_APP_APIS_URL } = process.env

export const refreshAutodeskAccessToken = async () => {
    try {
        //--- to prevent from duplicate api calls ---
        const call = getCookie("__bauhub_refresh_token_call")
        if (call) {
            return
        }
        // 0.000115741 days is equal to 10s
        setCookie("__bauhub_refresh_token_call", true, 0.000115741)
        //---xxx---

        const refreshToken = getCookie("__bauhub_refresh_token")
        if (!refreshToken) {
            throw new Error("Refresh token not found")
        }

        const refreshRes = await axios.get(`${REACT_APP_APIS_URL}/apis/auth/autodesk/refresh?refresh_token=${refreshToken}`)

        const refreshAccessTokenData = refreshRes?.data
        if (!refreshAccessTokenData) {
            throw new Error("Access token not refreshed successfully")
        }
       
        setTokenDataCookie(refreshAccessTokenData)
        return refreshAccessTokenData.access_token

    } catch (error) {
        console.log("Error while refreshing access token", error);
    }
       
}

export function setTokenDataCookie({ access_token, refresh_token }) {
    // converting expiry time in days as our cookie fn accepts the same
    setCookie("__bauhub_token", access_token, accessTokenExpiryTime);
    setCookie("__bauhub_refresh_token", refresh_token, 15)
}


export async function connectAccAutodesk({ api: connectToAcc, access_token }) {
    try {
        const accRes  = await connectToAcc({
            variables: {
              token: access_token,
            },
          })
        const { message, status } = accRes?.data?.connectToAcc || {};
        window.opener.postMessage({
            status,
            message,
            service: "connect-to-acc",
          });

    } catch (error) {
      alert(error?.message || "Some error occured while authenticating ACC")
    } finally {
        window.close();
    }
}

