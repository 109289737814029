import React, { useCallback, useEffect, useRef, useState } from 'react';
import LabelBox from '../label-box';
import ErrorText from '../error-text';
import ThreeDotsLoader from '../three-dots-loader';

const Code = ({ length: digits = 6, isError = false, message = '', loading = false, onSubmit }) => {

    //local states
    const [hasError, setHasError] = useState(isError)
    const [hasMessage, setHasMessage] = useState(message)
    const [codes, setCodes] = useState(Array(digits).fill(''))

    // all input refs
    const inputRefs = useRef([]);

    //updating state of on change of error & message
    useEffect(() => {
        setHasError(isError)
    }, [isError])

    useEffect(() => {
        setHasMessage(message)
    }, [message])

    const onChange = useCallback((ele, index) => {
        const value = ele.value || ''
        const isNumberOnlyOrEmpty = /^[0-9]?$/.test(value)

        if (hasError) {
            setHasError(false)
        }

        if (hasMessage) {
            setHasMessage('')
        }

        if (isNumberOnlyOrEmpty) {
            const newCodes = [...codes]
            newCodes[index] = value

            setCodes(newCodes);

            if (newCodes.every(Boolean)) {
                onSubmit && onSubmit(newCodes.join(''))
            }

            // focus on the next input
            if (value !== '' && ele.nextSibling) {
                ele.nextSibling.focus()
            }
        }

    }, [codes, hasError, hasMessage, onSubmit])

    const onClickBackspace = useCallback((event, index) => {
        const ele = event.target

        // clear the current value if backspace is pressed
        if (event.key === 'Backspace') {

            // only clearing value when value was there
            if (ele.value === '') {
                const newCodes = [...codes]
                newCodes[index] = ''

                setCodes(newCodes)
                // focus on the previous input
                if (ele.previousSibling) {
                    ele.previousSibling.focus()
                }
            }
        }
    }, [codes])

    const onPasteCode = useCallback((event) => {
        const pastedCode = event.clipboardData.getData('text')

        if (/^[0-9]+$/.test(pastedCode)) {
            const pastedCodeArray = pastedCode.slice(0, digits).split('');
            const newCodes = [...codes]

            pastedCodeArray.forEach((code, index) => {
                newCodes[index] = code
            });

            setCodes(newCodes)

            //shifting focus to the last digit input of the pasted code
            inputRefs.current[pastedCodeArray.length - 1].focus();

            if (newCodes.length === digits) {
                onSubmit && onSubmit(newCodes.join(''))
            }
        }
    }, [codes, digits, onSubmit])

    return (
        <div className={`code ${hasError ? 'code-error' : 'code-normal'}`} >
            <LabelBox label='Authentication code' />
            <div className="code-container">
                {codes.map((codeValue, index) => (
                    <input
                        className='code-input'
                        type='text'
                        key={index}
                        maxLength="1"
                        value={codeValue}
                        onPaste={onPasteCode}
                        ref={(el) => inputRefs.current[index] = el}
                        onChange={(event) => onChange(event.target, index)}
                        onKeyDown={(event) => onClickBackspace(event, index)}
                    />
                ))}

            </div>
            <div className='code-message-container'>
                {hasMessage && (
                    <ErrorText
                        isError={hasError}
                        message={<>
                            <img
                                className="message-icon"
                                src={`/images/icon/${hasError ? 'emoji-surprise-red' : 'match'}.svg`}
                                alt="message"
                            />
                            {hasMessage}
                        </>} />
                )}
                {loading && <ThreeDotsLoader />}
            </div>


        </div>
    );
}

export default Code;