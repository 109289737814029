import React, { useCallback, useEffect, useRef, useState } from "react";
import { fieldValidation } from "../../utils";
import ErrorText from "../error-text";
import LabelBox from "../label-box";

const InputUrl = ({
  id,
  required = false,
  placeholder = "https://",
  label,
  isError = false,
  errorMessage,
  fieldName,
  onChange,
  validations,
  showValidationMessage = false,
  infoPlacement,
  info,
  showSettings,
  onChangeSettings,
  defaultSettings,
  defaultValue,
  showAppliedSettings,
  onClickSettings,
  settingsValue,
  onDelete,
  ...props
}) => {

  const [hasError, setHasError] = useState(isError)
  const [hasErrorMessage, setHasErrorMessage] = useState(errorMessage)
  const settings = useRef(null)
  // fieldValue is being used in case fieldName is passed
  const fieldValue = useRef(null)

  //updating state of on change of error & message
  useEffect(() => {
    setHasError(isError)
  }, [isError])

  useEffect(() => {
    setHasErrorMessage(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    if (defaultValue && !fieldValue.current) {
      const defaultFieldValue = {
        [fieldName]: defaultValue,
      }

      if (defaultSettings) defaultFieldValue.settings = defaultSettings
      fieldValue.current = defaultFieldValue
    }
  }, [defaultSettings, defaultValue, fieldName])

  const onChangeInput = useCallback(
    (event) => {
      const value = event.target.value.trim();
      const { isValid, message } = fieldValidation(validations ? { ...validations, type: 'url' } : { type: 'url' }, value, label)

      if (!isValid) {
        if (!hasError) setHasError(true)
        if (message) setHasErrorMessage(message)
      } else {
        if (hasError) setHasError(false)
        if (hasErrorMessage) setHasErrorMessage('')
      }
      if (fieldName) {

        const argData = {
          [fieldName]: value,
        }
        if (settings?.current) argData.settings = settings.current
        fieldValue.current = argData
        onChange(
          argData,
          event
        )
      } else {
        onChange && onChange(value, event);
      }
    },
    [fieldName, onChange, validations, hasError, hasErrorMessage, label, settings]
  );

  const onChangeLabelSettings = useCallback(settingsData => {
    onChangeSettings && onChangeSettings(settingsData)
    settings.current = settingsData
    onChange && onChange(fieldValue.current ? { ...fieldValue.current, settings: settingsData } : { [fieldName]: null, settings: settingsData })
  }, [onChangeSettings, fieldValue, onChange, fieldName])

  return (
    <div
      className={`input-url input-text ${!hasError && !hasErrorMessage ? "input-text-normal" : "input-text-error"}`}
    >
      <LabelBox required={required} label={label} infoPlacement={infoPlacement} info={info} showSettings={showSettings} onChangeSettings={onChangeLabelSettings} defaultSettings={defaultSettings} showAppliedSettings={showAppliedSettings} onClickSettings={onClickSettings} settingsValue={settingsValue} onDelete={onDelete}/>
      <input
        {...props}
        id={id}
        placeholder={placeholder}
        type="url"
        onChange={onChangeInput}
        defaultValue={defaultValue}
      />
      {showValidationMessage && <ErrorText message={hasErrorMessage} />}
    </div>
  );
};

export default InputUrl;
