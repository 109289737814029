import React, { useContext } from "react";
import ToastContextWithProvider from "./context-with-provider"

export const ToasterContext = React.createContext({
    toastData: [],
    showToaster: (id, description, isSuccess, title, type) => { },
    addToastMessage: (id, description, isSuccess, title, type) => { }
});

export default ToastContextWithProvider;

export const useToaster = () => {
    return useContext(ToasterContext);
};
