import { useAtom, useSetAtom } from "jotai";
import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import {
  organizationState,
  permissionsState,
  planDetailsState,
  propertyValueOptionsState,
  userFeaturesPermissionsState,
  userState,
} from "../atoms";
import { client } from "../common/apollo/ApolloClient";
import { CONSTANTS } from "../constants";
import {
  GET_ORGANIZATION,
  GET_PERMISSIONS,
  GET_PROPERTY_VALUE_OPTIONS,
  GET_USER,
} from "../graphqlQueries";
import {
  getCookie,
  isPlanner,
  isVendor,
  setCookie,
  transformPropertyValueOptions,
} from "../utils";

export default function useUserOrganizationPermissionData() {
  //
  const setPermissions = useSetAtom(permissionsState);
  const [userFeaturesPermissions, setUserFeaturesPermissions] = useAtom(
    userFeaturesPermissionsState
  );

  //recoil
  const setPlanDetailsState = useSetRecoilState(planDetailsState);
  const setUserState = useSetRecoilState(userState);
  const setOrganization = useSetRecoilState(organizationState);
  const setPropertyValueOptionsState = useSetRecoilState(
    propertyValueOptionsState
  );

  const fetchUserOrgPermission = useCallback(async () => {
    try {
      //User
      const userAPI = await client.query({
        query: GET_USER,
        partialRefetch: true,
      });

      const userData = userAPI?.data?.getUser;
      const userAllOrganizations = userData?.organizations;
      const prevOrgId = getCookie("__current_organization");

      // if not getting prevOrgId then setting the first organization as default one
      const currentOrgId =
        userAllOrganizations?.length > 1
          ? prevOrgId || userAllOrganizations[0].id
          : userAllOrganizations[0].id;
      setCookie("__current_organization", currentOrgId);

      const intialApisToCall = [
        GET_ORGANIZATION,
        GET_PERMISSIONS,
        GET_PROPERTY_VALUE_OPTIONS,
      ].map((typeOfAPI) =>
        client.query({
          query: typeOfAPI,
          partialRefetch: true,
        })
      );

      const userAndOrgData = await Promise.allSettled(intialApisToCall);

      const [
        getOrganizationData,
        getPermissionsData,
        getPropertyValueOptionsData,
      ] = userAndOrgData.map((promiseResponse) => promiseResponse?.value?.data);

      //Property Value Options
      const propertyValueOptionsData =
        getPropertyValueOptionsData?.getPropertyValueOptions;

      //Org
      const organization = getOrganizationData?.getOrganization;
      const orgSubscriptionPlan = organization?.subscription?.plan || "FREE";

      //Plan

      const permissionData = getPermissionsData?.getPermissions;
      const allPlans = permissionData.organizationPlans;

      const currentPlanDetails = allPlans.find(
        (plan) => plan.id === orgSubscriptionPlan
      );

      const isPlannerOrganization = isPlanner(currentPlanDetails?.id);
      const isVendorOrganization = isVendor(currentPlanDetails?.id);

      //create object to create condition based on current user and org
      const orgFeatures =
        (permissionData?.organizationPlans || []).find(
          (plan) => plan.id === orgSubscriptionPlan
        )?.features || [];
      const roles =
        (userAllOrganizations || []).find((org) => org.id === organization.id)
          ?.roles || [];
      const orgPermissions = permissionData?.userRoles
        .filter((role) => roles.includes(role.id))
        .map((role) => role?.permissions || [])
        .flat();
      const data = {
        features:
          orgSubscriptionPlan === "FREE"
            ? {
                [CONSTANTS.FEATURES.PROJECT_VIEW]: true,
              }
            : {},
        permissions: {},
      };

      orgFeatures.forEach((feat) => {
        data.features[feat?.id] = !!feat?.value;
      });
      orgPermissions.forEach((per) => {
        data.permissions[per] = true;
      });

      // saving to global states
      setUserState(userData);
      setPropertyValueOptionsState(
        transformPropertyValueOptions(propertyValueOptionsData)
      );
      const finalFeaturesPermissions = {
        features: { ...userFeaturesPermissions.features, ...data.features },
        permissions: {
          ...userFeaturesPermissions.permissions,
          ...data.permissions,
        },
      };

      setUserFeaturesPermissions(finalFeaturesPermissions);
      setPermissions(permissionData);
      setPlanDetailsState(currentPlanDetails);
      setOrganization({
        ...organization,
        isPlanner: isPlannerOrganization,
        isVendor: isVendorOrganization,
      });

      // clearing the local data that graphql is storing
      client.stop();
      await client.clearStore();
    } catch (error) {
      console.log("Error in Plan/Org/User details API", error);
    }
  }, [
    setUserState,
    setPropertyValueOptionsState,
    setPermissions,
    setPlanDetailsState,
    setOrganization,
    setUserFeaturesPermissions,
    userFeaturesPermissions,
  ]);

  return { fetchUserOrgPermission };
}
