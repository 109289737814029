import React, { useCallback, useEffect, useState } from "react";
import Button from "../button";

export default function Alert({ visible, children, text, onClose, buttonText = false, onButtonClick, isActionButtonLoading, isAlertClosing, withoutCloseButton = false }) {
  const [showAlert, setShowAlert] = useState(true)

  const handleCloseAlert = useCallback(() => {
    if (onClose) {
      onClose();
    }
    if (typeof visible !== "boolean") {
      setShowAlert(false)
    }
  }, [onClose, visible])

  const handleActionButtonClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick()
    }
  }, [onButtonClick])

  useEffect(() => {
    if (typeof visible === "boolean") {
      setShowAlert(visible)
    }
  }, [visible])

  return (
    showAlert ? (
      <div className={`bimmatch-alert ${isAlertClosing ? "closing" : ""}`}>
        <div className="alert-content">
          {children ? children : (<p className="alert-text">{text}</p>)}
        </div>
        <div className="alert-actions">
          {buttonText && <Button className="alert-action-button" onClick={handleActionButtonClick} loading={isActionButtonLoading}>{buttonText}</Button>}
          {!withoutCloseButton && <img src="/images/icon/close-white.svg" className="close-button" alt="close" onClick={handleCloseAlert} />}
        </div>
      </div>
    ) : (
      <></>
    )
  );
}
