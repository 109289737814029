import React, { useCallback, useMemo } from 'react';
import Tree from '../tree';

const TreeType = ({ searchData, totalSelectedData, onSelectOption, onDeselectOption, searchInput, multiple, ...props }) => {

    const totalSelectedDataIds = useMemo(() =>  (totalSelectedData?.length ? (multiple ? totalSelectedData.map(selectedData => selectedData.id) : totalSelectedData[0].id ) : []) , [totalSelectedData, multiple])

    const onBranchSelect = useCallback((isSelected, branchData) => {
        if (isSelected) {
            onSelectOption(branchData)
        } else {
            onDeselectOption(branchData)
        }
    }, [onDeselectOption, onSelectOption])

    return ( 
        <div className='select-tree-container'>
            <Tree {...props} treeData={searchData} onBranchSelect={onBranchSelect} value={totalSelectedDataIds} filterText={searchInput} multiple={multiple}/>
        </div>
     );
}
  
export default TreeType;