import React from "react";

const powerUnits = ['cm^3', 'cm^2', 'm^2', 'm^3', 'ft^2', 'ft^3'];
const co2Units = ["Kg CO2 equ", "Kg CO2 e", "CO2 e"];

function PropertyUnit({ unit }) {
  if (!unit) {
    return <></>;
  }

  if (co2Units.includes(unit)) {
    const [prefix, postfix] = unit.split("CO2");
    return (
      <>
        <span className="value-field">
          {prefix}CO<sub>2</sub>{postfix}
        </span>
      </>
    );
  }

  if (powerUnits.includes(unit)) {
    const unitAndPower = unit.split('^')
    return (
      <>
        <span className="value-field">
          {unitAndPower[0]}<sup>{unitAndPower[1]}</sup>
        </span>
      </>
    );
  }

  return (
    <>
      <span className="value-field">{unit}</span>
    </>
  );
}

export default PropertyUnit;
