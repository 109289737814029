import { useEffect } from 'react';

const useResizeObserver = (ref, onResize) => {
    useEffect(() => {
        if (!ref) return;

        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if ((entry.target === ref) && onResize) {
                    onResize(entry.contentRect);
                }
            }
        });

        observer.observe(ref);

        return () => observer.disconnect();
    }, [ref, onResize]);
};

export default useResizeObserver;
