import React, { useMemo } from "react";

export default function Avatar(props) {
  const { url, firstName='XY', lastName } = props;
  const initials = useMemo(() => {
    let initials = firstName?.charAt?.(0) || "";
    initials += lastName
      ? lastName?.charAt?.(0) || ""
      : firstName?.charAt?.(1) || "";
    return initials;
  }, [firstName, lastName]);

  return (
    <div className="bimmatch-avatar">
      {url ? (
        <img
          src={url}
          className="img-fluid"
          alt="profile"
          //   style={url ? {} : { transform: "scale(2)" }}
        />
      ) : (
        <div className="initials">
          <h3>{initials}</h3>
        </div>
      )}
    </div>
  );
}
