import React, { useEffect, useRef, useState } from "react";
import useLoadScript from "./useLoadScript";

const Maps = (
    { onClickMarker, selectedLocations, icon, lat = 0, lng = 0, zoom = 1, style = { height: "490px" }, mapRef },
    { key = process.env.REACT_APP_GOOGLE_MAP_KEY } = {}
) => {
    const isScriptLoaded = useLoadScript({ key });
    const mapDomRef = useRef(null);
    const [map, setMap] = useState(null);
    // const [existingMarkers, setExistingMarkers] = useState([]);

    useEffect(() => {
        if (isScriptLoaded && mapDomRef && mapDomRef.current) {
            let selectedLocationsWithoutNull = selectedLocations?.filter(location => location !== null)
            selectedLocationsWithoutNull = selectedLocationsWithoutNull || []
            selectedLocationsWithoutNull.forEach((markerPosition) => {
                const marker = window.google && window.google.maps && new window.google.maps.Marker({
                    position: { lat: markerPosition.lat, lng: markerPosition.lng }
                });
                marker.setMap(null)
            });

            const mapObj = new window.google.maps.Map(mapDomRef.current, {
                center: { lat: lat, lng: lng },
                zoom: zoom,
                streetViewControl: false
            });

            setMap(mapObj);

            mapRef && mapObj && mapRef(mapObj);
        }
    }, [isScriptLoaded, mapDomRef, mapRef, lat, lng, zoom, selectedLocations]);

    useEffect(() => {
        if (map && selectedLocations?.length) {

            let bounds = new window.google.maps.LatLngBounds();
            const newMarkers = [];
            let selectedLocationsWithoutNull = selectedLocations?.filter(location => location !== null)
            selectedLocationsWithoutNull = selectedLocationsWithoutNull || []
            selectedLocationsWithoutNull.forEach((markerPosition) => {
                // const markerZoom = selectedLocations.length === 1 ? 15 : zoom;
                const marker = window.google && window.google.maps && new window.google.maps.Marker({
                    position: { lat: markerPosition.lat, lng: markerPosition.lng },
                    map,
                    icon: icon,
                    zoom: zoom,
                });

                marker && marker.addListener('click', (e) => onClickMarker && onClickMarker(e, marker));
                bounds.extend(marker.position);
                newMarkers.push(marker);

            });

            // map.fitBounds(bounds);
            if (!selectedLocationsWithoutNull.length) {
                map.setZoom(8);
            }
            else if (selectedLocationsWithoutNull.length === 1) {
                map.setZoom(5);
                map.setCenter(newMarkers[0].getPosition());
            } else {
                map.fitBounds(bounds);
            }
        }
    }, [map, selectedLocations, onClickMarker, zoom, icon]);


    if (!isScriptLoaded) {
        return null;
    }

    return (
        <div ref={mapDomRef} style={style}>
            {" "}
        </div>
    );
};

export default Maps;

