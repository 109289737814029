import { useState, useEffect, useRef, useCallback } from 'react';

/**
 * hook to manage timer using only the expiration timestamp.
 * 
 * @param {number} duration - the total duration of the OTP timer (in seconds).
 * @param {string} storageKey - the key used to store the expiration timestamp in localStorage.
 * @returns {Object} - returns an object with remaining time, functions to reset and abort the timer.
 */

const useTimer = (duration, storageKey) => {
  const [timeLeft, setTimeLeft] = useState(getDefaultTime(storageKey));
  const timerRef = useRef(null);

  useEffect(() => {
    // retrieve and set the expiration time from localStorage
    const savedExpiration = localStorage.getItem(`${storageKey}_expires`);

    if (savedExpiration) {
      const now = Date.now();
      const expirationTime = parseInt(savedExpiration);
      const remainingTime = Math.max(0, Math.floor((expirationTime - now) / 1000));

      setTimeLeft(remainingTime);
    }
  }, [storageKey]);

  // if timer left initiating the countdown
  useEffect(() => {
    if (timeLeft > 0) {
        timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0 && timerRef.current) { // clear the expiration time if the timer is up
      localStorage.removeItem(`${storageKey}_expires`); 
    }

    return () => clearInterval(timerRef.current); // Cleanup on unmount
  }, [timeLeft, storageKey]);

  // to restart the timer
  const resetTimer = useCallback(() => {
    setTimeLeft(duration);
    const expirationTimestamp = Date.now() + duration * 1000;
    localStorage.setItem(`${storageKey}_expires`, expirationTimestamp)
  }, [duration, storageKey])

  // to abort (stop) the timer
  const abortTimer = useCallback(() => {
    clearInterval(timerRef.current)
    timerRef.current = null
    localStorage.removeItem(`${storageKey}_expires`)
    setTimeLeft(0);
  }, [storageKey])


  return { timeLeft, resetTimer, abortTimer };
};

// retrieve and return the expiration time from localStorage
function getDefaultTime(storageKey) {
    const savedExpiration = localStorage.getItem(`${storageKey}_expires`);

    let remainingTime = 0

    if (savedExpiration) {
        const now = Date.now();
        const expirationTime = parseInt(savedExpiration);
        remainingTime = Math.max(0, Math.floor((expirationTime - now) / 1000));
    }

    return remainingTime
} 

export default useTimer