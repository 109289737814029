import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOutsideClicked } from "../../hooks";

const Popover = ({
  children,
  content = "",
  defaultVisible = false,
  placement = "top",
  showOnHover = false,
  theme = "white",
  hideArrow = false,
  onClick,
  className,
  onClickOutside,
}) => {
  const [isVisible, setIsVisible] = useState(defaultVisible);
  const popoverRef = useRef(null);
  const intervaRef = useRef(null);

  const onClickOutsidePopover = useCallback(() => {
    setIsVisible(false);
    onClickOutside && onClickOutside();
  }, [onClickOutside]);

  useOutsideClicked(popoverRef, onClickOutsidePopover);

  const onClickParent = useCallback(
    (e) => {
      e.stopPropagation();
      onClick && onClick(!isVisible);
      if (!showOnHover) setIsVisible(!isVisible);
    },
    [isVisible, showOnHover, onClick]
  );

  const onMouseEnterOrLeave = useCallback(() => {
    if (showOnHover) {
      if (intervaRef.current) {
        clearTimeout(intervaRef.current);
        intervaRef.current = null;
        setIsVisible(false);
      } else {
        intervaRef.current = setTimeout(() => {
          setIsVisible(true);
        }, 100);
      }
    }
  }, [showOnHover]);

  useEffect(() => {
    if (!popoverRef.current) return;
    // handle tooltip out of screen for left side
    const popoverContent = popoverRef.current.querySelector(".popover-content");
    if (!popoverContent) return;
    const { x, y, height } = popoverContent.getBoundingClientRect();

    if (showOnHover && x < 0) {
      popoverContent.style.left = `${-x + 16}px`;
      const popoverArrow = popoverContent.querySelector(".popover-arrow");
      if (!popoverArrow) return;
      const { x: arrowX } = popoverArrow.getBoundingClientRect();
      popoverArrow.style.left = `${arrowX - -x + 10 - 16}px`;
    }

    //out of screen in bottom part
    if (y + height > window.innerHeight) {
      const diff = window.innerHeight - (y + height);
      popoverContent.style.top = `${diff}px`;
      const popoverArrow = popoverContent.querySelector(".popover-arrow");
      if (!popoverArrow) return;
      popoverArrow.remove();
    }
  }, [content, isVisible, showOnHover]);

  return (
    <div
      ref={popoverRef}
      className={`bimmatch-popover bimmatch-popover-${theme} ${
        className || ""
      }`}
      onClick={(e) => onClickParent(e)}
      onMouseEnter={onMouseEnterOrLeave}
      onMouseLeave={onMouseEnterOrLeave}
    >
      {children}
      {content && isVisible && (
        <div
          className={`popover-content ${"popover-content-" + placement}`}
          onClick={(e) => e.stopPropagation()}
        >
          {content}
          {/* <div className="popover-arrow">
            <span className='popover-arrow'></span>
          </div> */}
          {!hideArrow && <span className="popover-arrow"></span>}
        </div>
      )}
    </div>
  );
};

export default Popover;
