import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { productBrandsState, productCategoriesState, productDistributorsState } from "../atoms";
import { GET_BRANDS, GET_CATEGORIES, GET_DISTRIBUTORS } from "../graphqlQueries";
import { deepClone } from "../utils";

/**
 * Hook to get categories, brands and distributors
 *
 */
export default function useGetFacetsData() {
    const [productCategories, setProductCategories] = useRecoilState(productCategoriesState)
  const [productBrands, setProductBrands] = useRecoilState(productBrandsState)
  const [productDistributors, setProductDistributors] = useRecoilState(productDistributorsState)

  // ---API call for all the categories
  const {
    data: categoriesList = { }
  } = useQuery(GET_CATEGORIES, { skip: productCategories.length })

  // ---API call for all brands list
  const {
    data: brandsList = { }
  } = useQuery(GET_BRANDS, { skip: productBrands.length })

  // ---API call for all distributors list
  const {
    data: distributorsList = { }
  } = useQuery(GET_DISTRIBUTORS, { skip: productDistributors.length })

  useEffect(() => {
    if (!productCategories.length && categoriesList?.categories) {
      const categories = deepClone(categoriesList?.categories || []) 
      const sortedCategories = categories.sort((a, b) => ((a.displayName > b.displayName) ? 1 : (b.displayName > a.displayName ? -1: 0)))
      setProductCategories(sortedCategories)
    }

    if (!productBrands.length && brandsList?.brands) {
      setProductBrands(brandsList?.brands)
    }

    if (!productDistributors.length && distributorsList?.distributors) {
      setProductDistributors(distributorsList?.distributors)
    }
  }, [productCategories, productBrands, productDistributors, setProductBrands, setProductCategories, setProductDistributors, categoriesList.categories, brandsList.brands, distributorsList.distributors])

 
  return {categories:productCategories, brands:productBrands, distributors:productDistributors}

}
