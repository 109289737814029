import React from 'react'
import Tag from '../tag'

const KeyValuePair = ({ keyName, value, children, type = "", withColon = true }) => {
  return (
    <div className={`bimmatch-key-value-pair ${React.isValidElement(children) ? "custom" : type}-property-value-wrapper`}>
      <KeyField type={type} keyName={keyName} withColon={withColon} />
      {!!children ? children : <ValueField type={type} value={value} />}
    </div>
  )
}

const KeyField = ({ type, keyName, withColon }) => {
  return <span className={`key-field value-field-${type}`}>{keyName}{withColon ? ":" : ""}</span>
}

const ValueField = ({ type, value }) => {
  switch (type) {
    case "tags":
      return (
        <div className='tags-wrapper'>
          {value.map(tagData => <Tag key={tagData.id} name={tagData.displayName} color={tagData?.color || null} />)}
        </div>
      )
    default:
      return (
        <span className={`value-field value-field-${type}`}>{getValueBasedOnType(type, value)}</span>
      )
  }
}

const getValueBasedOnType = (type, value) => {
  switch (type) {
    case "array":
      return value.join(", ");
    case "boolean":
      return value ? "Yes" : "No"
    default:
      return ((typeof value === "string") ? value : JSON.stringify(value))
  }
}

export default KeyValuePair