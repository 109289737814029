import React from "react";

const ErrorText = ({ left = false, message = "", isError = true }) => {
  return (
    <div
      className={`error-text ${left ? "error-text-left" : "error-text-right"} ${
        isError ? "error-text-error" : "error-text-normal"
      }`}
    >
      {message}
    </div>
  );
};

export default ErrorText;
