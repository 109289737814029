import React from "react";

const LevelIdentifier = React.memo(({ level = 0 }) => {
    if (level > 1) {
        return Array(level - 1)
            .fill(1)
            .map((itm, idx) => (
                <div key={idx} className={`tree-level level-${level}`}></div>
            ));
    }
    return null;
});

export default LevelIdentifier