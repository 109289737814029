import { useQuery } from "@apollo/client";
import { GET_PRODUCT_DETAIL } from "../graphqlQueries";
import { useMemo } from "react";
import { isUUID } from "../utils";
import usePropertiesNew from "./usePropertiesNew";

export default function useProductDetail({ id, typeId }) {
  // API call to get product details
  const { loading, data: productDetailsData } = useQuery(GET_PRODUCT_DETAIL, {
    variables: { id },
  });

  const product = useMemo(
    () => productDetailsData?.getProduct ?? {},
    [productDetailsData]
  );

  const {
    categories,
    properties,
    organizationId: productOrgId,
    files,
    types,
    images,
    description,
  } = useMemo(() => product, [product]);

  const { filterData } = usePropertiesNew(productOrgId);

  const filteredTypes = useMemo(() => Array.isArray(types) ? types.filter((type) => (Array.isArray(type.properties) && type.properties.length) || (Array.isArray(type.files) && type.files.length) || type.description) : [], [types]);
  const selectedType = useMemo(() => isUUID(typeId) ? filteredTypes.find((type) => type.id === typeId) : undefined, [filteredTypes, typeId]);

  // take property only if they have value
  // remove property with checkbox with value false
  const filterDataInput = useMemo(() => {
    let inputPropertyIds = [];
    const propertiesToDisplay = selectedType
      ? selectedType.properties
      : properties;
    const filesToDisplay = selectedType ? selectedType.files : files;
    inputPropertyIds.push(
      ...(Array.isArray(propertiesToDisplay) ? propertiesToDisplay : []).filter(
        (prop) => prop?.value || prop?.values?.length
      )
    );
    inputPropertyIds.push(
      ...(Array.isArray(filesToDisplay) ? filesToDisplay : [])
    );
    return inputPropertyIds;
  }, [selectedType, properties, files]);

  const filteredData = useMemo(() => {
    if (filterData) {
      const properties = filterData(filterDataInput) || [];
      return properties.filter((property) => !(property.subType === "checkbox" && (property.property?.value === "false" || property.property?.value === false)));
    }
    return [];
  }, [filterData, filterDataInput]);

  const MainCategories = Array.isArray(categories)
    ? categories.filter((category) => !category?.parentCategoryId)
    : [];

  const filteredImages = useMemo(() => {
    const imgs = selectedType ? selectedType.images : images;
    return Array.isArray(imgs) ? imgs : [];
  }, [selectedType, images]);

  const filteredDiscription = useMemo(
    () => (selectedType ? selectedType.description : description),
    [selectedType, description]
  );

  return {
    product,
    filteredData,
    loading,
    MainCategories,
    types: filteredTypes,
    images: filteredImages,
    description: filteredDiscription,
  };
}
