import React  from "react";
import CategorisedSearch from "../categorised-search";
import { useCountries } from "../../hooks";

const SelectLocation = ({
  countriesData,
  onChangeSearchInput,
  onSelectChips,
  placeholder = "Distributors location",
  width = 260,
  countryPriority,
  ...props
}) => {

  const countryData = useCountries();

  return (
    <div className="select-location" style={{ width }}>
      <CategorisedSearch
        searchData={countryData}
        onChangeSearchInput={onChangeSearchInput}
        onSelectChips={onSelectChips}
        type="select"
        placeholder={placeholder}
        searchIcon={"../../images/icon/location.svg"}
        {...props}
      />
    </div>
  );
};

export default SelectLocation;
