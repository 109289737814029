import React, { useCallback, useState } from "react";
import { Toast } from "../../../components";
import { ToasterContext } from ".";

const ToastContextWithProvider = ({ children }) => {
    const [toastData, setToastData] = useState([]);

    const showToaster = useCallback((id, description, isSuccess, title, type) => {
        setToastData([getToastPayload(id, description, isSuccess, title, type)]);
    }, [])

    const addToastMessage = useCallback((id, description, isSuccess, title, type) => {
        setToastData(oldState => ([getToastPayload(id, description, isSuccess, title, type), ...oldState]));
    }, [])

    return (
        <ToasterContext.Provider value={{ showToaster, addToastMessage, toastData }}>
            {children}
            <Toast
                toastList={toastData}
                position={"bottom-right"}
                autoDeleteTime={toastData?.duration || 12000}
            />
        </ToasterContext.Provider>
    )
}

const getToastPayload = (id, description, isSuccess, title, type) => {
    return {
        id,
        title: title || (isSuccess ? "Success" : "Oops"),
        description,
        type: type || (isSuccess ? "success" : "error"),
    };
}

export default ToastContextWithProvider;