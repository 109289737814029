import React from "react";

const TreeLevelIcon = React.memo(({ open = false, onClick, loading }) => {
    if (loading) {
        return (
            <div className="row-loading">
                <span className="bimmatch-button-loading" />
            </div>
        );
    }
    if (open) {
        return (
            <div onClick={onClick} className="tree-icon open">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                </svg>
            </div>
        );
    }
    return (
        <div onClick={onClick} className="tree-icon closed">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="bi bi-chevron-right"
                viewBox="0 0 16 16"
            >
                <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
            </svg>
        </div>
    );
});

export default TreeLevelIcon