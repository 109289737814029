import React, { useState, useRef, useEffect, useCallback } from "react";
import CustomAnchor from "../custom-anchor";

const TruncateText = ({ text, maxLines = 3 }) => {
    const [isTruncated, setIsTruncated] = useState(true);
    const [showButton, setShowButton] = useState(false);
    const [breakWord, setBreakWord] = useState(false)
    const textRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const description = textRef.current
        const container = containerRef.current;

        const checkTruncation = () => {
            if (description) {
                const lineHeight = parseInt(window.getComputedStyle(description).lineHeight);
                const maxHeight = lineHeight * maxLines;
                if (description.scrollHeight > maxHeight) {
                    setShowButton(true)
                } else {
                    if (container && (container.clientWidth < description.scrollWidth)) {
                        setShowButton(true)
                        setBreakWord(true)
                    }
                }
            }
        };

        // Initial check
        checkTruncation();

        const resizeObserver = new ResizeObserver(() => {
            checkTruncation();
        });

        if (description) {
            resizeObserver.observe(description);
        }

        return () => {
            if (description) {
                resizeObserver.unobserve(description);
            }
        };
    }, [maxLines]);

    const toggleTruncate = useCallback(() => {
        setIsTruncated(!isTruncated);
    }, [isTruncated]);

    return (
        <div className="bimmatch-truncate-text" ref={containerRef}>
            <p
                ref={textRef}
                style={{ WebkitLineClamp: isTruncated ? maxLines : 'unset' }}
                className={`description ${breakWord ? "break-word" : ""}`}
            >
                {text}
            </p>
            {showButton && (
                <CustomAnchor onClick={toggleTruncate}>
                    {isTruncated ? 'Show more' : 'Show less'}
                </CustomAnchor>
            )}
        </div>
    );
};

export default TruncateText