import { useEffect } from "react";

/**
 * Hook that checks clicks outside of the passed ref
 */

/**
 *hook to check if clicked outside the passed ref
 *
 * @param ref - refrence
 * @param {Function} callbackFn - function to be execeuted on clicking outside the passed ref
 */

export default function useOutsideClicked(ref, callbackFn) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callbackFn && callbackFn(event);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, callbackFn]);
}
