import React, { useCallback, useState } from "react";
import ErrorText from "../error-text";
import InputText from "../input-text";
import Toggle from "../toggle";

const defaultToggleData = [
  { id: "current", displayName: "This Category" },
  { id: "all", displayName: "All Categories" },
];

const InputWithToggle = ({
  isInputrequired = true,
  isToggleRequired = false,
  onChangeInput,
  onChangeToggle,
  inputLabel = "Property name",
  toggleLabel = "Applicable in...",
  errorMessage,
  hideInputLabel,
  hideToggleLabel,
  defaultToggleValue = defaultToggleData[0].id,
  toggleData = defaultToggleData,
  fieldName,
  inputFieldName = "name",
  toggleFieldName = "type",
  onChange,
  onRemove,
  disableToggle,
  hideToggle,
  removable = true
}) => {
  const [toggleValue, setToggleValue] = useState({
    [toggleFieldName]: defaultToggleValue,
  });
  const [inputValue, setInputValue] = useState(null);

  const onChangeInputData = useCallback(
    (data, event) => {
      setInputValue(data);
      onChangeInput && onChangeInput(data, event);
      if (onChange) {
        let allChangedData = {};
        if (fieldName) {
          allChangedData[fieldName] = !hideToggle ? { ...toggleValue, ...data } : data
        } else {
          allChangedData = !hideToggle ? { ...toggleValue, ...data } : data
        }
        onChange(allChangedData, inputFieldName, event);
      }
    },
    [onChange, onChangeInput, toggleValue, inputFieldName, fieldName, hideToggle]
  );

  const onChangeToggleData = useCallback(
    (changedData, item) => {
      setToggleValue(changedData);
      onChangeToggle && onChangeToggle(changedData, item);
      if (onChange) {
        let allChangedData = {};
        if (fieldName) {
          allChangedData[fieldName] = { ...inputValue, ...changedData };
        } else {
          allChangedData = { ...inputValue, ...changedData };
        }
        onChange(allChangedData, toggleFieldName, item);
      }
    },
    [onChange, onChangeToggle, inputValue, toggleFieldName, fieldName]
  );

  const onRemoveInput = useCallback(event => {
    event.stopPropagation()
    event.preventDefault()
    onRemove && onRemove()
  }, [onRemove])

  return (
    <div className="inputWithToggle">
      <div className="inputWithToggle-container">
        <div className="inputWithToggle-input">
          <InputText
            label={inputLabel}
            onChange={onChangeInputData}
            hideLabel={hideInputLabel}
            required={isInputrequired}
            fieldName={inputFieldName}
          />
        </div>

        {!hideToggle && (
          <div className="inputWithToggle-toggle">
            <Toggle
              defaultValue={defaultToggleValue}
              label={toggleLabel}
              onChange={onChangeToggleData}
              hideLabel={hideToggleLabel}
              data={toggleData}
              required={isToggleRequired}
              fieldName={toggleFieldName}
              disable={disableToggle}
            />
          </div>
        )}
        
        {removable && (
          <img
            onClick={onRemoveInput}
            className="remove"
            src="/images/icon/close.svg"
            alt="remove"
          />
        )}
      </div>

      {errorMessage && <ErrorText message={errorMessage} />}
    </div>
  );
};

export default InputWithToggle;
