import React, { forwardRef } from 'react'

const Button = forwardRef(({
  name = 'Click',
  type = 'primary',
  children,
  disable = false,
  loading = false,
  selected,
  theme ='red',
  className,
  ...props
}, ref) => {
  
  return (
    <div
      {...props} 
      className={`bimmatch-button ${type === 'primary' ? 'bimmatch-button-primary' : 'bimmatch-button-secondary'} ${disable ? 'bimmatch-button-disabled' : 'bimmatch-button-enabled'} ${loading ? 'bimmatch-button-loading' : ''} ${theme ? 'bimmatch-button-'+theme : ''} ${className || ''}`}
      ref={ref}
      dir={'auto'}
    >
      {!loading && <p className='bimmatch-button-name'> {children || name}</p>}
    </div>
  )
})

export default Button
