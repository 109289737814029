import React from "react";

export default function BadgeNumber(props) {
  const { count } = props;
  return (
    <div className="badge-counter">
      <p className="count">{count}</p>
    </div>
  );
}
