import React from 'react';
import { useGetFacetsData } from '../../hooks';
import { bimmatchSupportEmail, modelingCategoriesHierarchy } from '../../utils';
import Select from '../select';

function CategorySelectMultiple({ defaultCategories, onSelectCategories, categoryValidations, multipleCategory, requiredCategory, onClickSuggestUs,

}) {
    const { categories } = useGetFacetsData();
    const categoryOptions = modelingCategoriesHierarchy(categories)

    function commonSuggestUsUrl(subject) {
        return `mailto:${bimmatchSupportEmail}?subject=${subject}`
    }

    return (
        <div> <Select
            label={'Categories'}
            required={requiredCategory ? requiredCategory : true}
            placeholder={'Search Categories'}
            multiple={multipleCategory ? multipleCategory : true}
            options={categoryOptions}
            onSelect={onSelectCategories}
            validations={{ required: categoryValidations ? categoryValidations : true }}
            showValidationMessage={true}
            suggestUsUrl={commonSuggestUsUrl('Suggestion for a new category!')}
            suggestUsText={'Suggest us a new category'}
            defaultValue={defaultCategories}
            onClickSuggestUs={onClickSuggestUs}
        /></div>
    )
}

export default CategorySelectMultiple