import React, { useMemo } from 'react'
import Chip from '../chip'
import Tag from '../tag'

const PriorityDescription = ({ type, count = 0, title, tags = [], hideCount = false }) => {
    const priorityType = useMemo(() => type ? type : (isNaN(count) ? "info" : ((count <= 3) ? "info" : ((count <= 7) ? "warning" : "danger"))), [type, count])

    return (
        <div className='bimmatch-priority-description'>
            {!!title && <pre className='description'>{title}</pre>}
            <div className='importance-tag-wrapper'>
                {!hideCount && (
                    <div className='importance-wrapper'>
                        <Chip iconSrc={`/images/icon/exclamation-${priorityType}.svg`} name={count} />
                    </div>
                )}
                <div className='tags-wrapper'>{tags.map(tagData => (<Tag key={tagData.id} name={tagData.displayName} color={tagData?.color || null}/>))}</div>
            </div>
        </div>
    )
}

export default PriorityDescription