import React, { useCallback, useEffect, useRef, useState } from "react";
import { generateUuid } from "../../utils";
import ErrorText from "../error-text";
import LabelBox from "../label-box";

const defaultActiveId = generateUuid();
const defautData = [
  { id: defaultActiveId, displayName: "First" },
  { id: generateUuid(), displayName: "Second" },
];

const Toggle = ({
  id,
  required,
  onChange,
  disable,
  data = defautData,
  defaultValue,
  label,
  hideLabel = false,
  fieldName,
  errorMessage,
  showValidationMessage = false,
  infoPlacement,
  showSettings,
  info,
  onChangeSettings,
  defaultSettings,
  showAppliedSettings,
  onClickSettings,
  settingsValue,
  onDelete,
  value,
  background= 'white'
}) => {
  const options = data.length >= 2 ? data : defautData;
  const defaultValueOfData = data ? options[0].id : defaultActiveId;

  const [activeToggleId, setActiveToggleId] = useState(defaultValueOfData);
  const [defaultValueSetStatus, setDefaultValueSetStatus] = useState(false)
  const settings = useRef(null)
  // fieldValue is being used in case fieldName is passed
  const fieldValue = useRef(null)

  useEffect(() => {
    if (!value && !defaultValueSetStatus && defaultValue) {
      setDefaultValueSetStatus(true)
      setActiveToggleId(defaultValue)
      const defaultFieldValue = {
        [fieldName]: defaultValue
      }

      if (defaultSettings) defaultFieldValue.settings = defaultSettings
      fieldValue.current = defaultFieldValue
    }
  }, [defaultSettings, defaultValue, defaultValueSetStatus, fieldName, value])

  useEffect(() => {
    if (value) {
      setActiveToggleId(value)
    }
  }, [value])

  const onClickToggle = useCallback(
    (toggleData) => {
      const selectedToggleId = toggleData.id;
      if (fieldName) {
        const argData = { [fieldName]: selectedToggleId }
        if (settings.current) argData.settings = settings.current
        fieldValue.current = argData
        onChange && onChange(argData, toggleData);
      } else {
        onChange && onChange(selectedToggleId, toggleData);
      }
      setActiveToggleId(value || selectedToggleId);
    },
    [onChange, fieldName, settings, value]
  );

  const onChangeLabelSettings = useCallback(settingsData => {
    onChangeSettings && onChangeSettings(settingsData)
    settings.current = settingsData
    onChange && onChange(fieldValue.current ? { ...fieldValue.current, settings: settingsData } : { [fieldName]: null, settings: settingsData })
  }, [onChangeSettings, fieldValue, onChange, fieldName])

  return (
    <div className={`toggle-container toggle-container-${background} ${!disable ? "toggle-container-enabled" : "toggle-container-disabled"}`}>
      {!hideLabel && <LabelBox label={label} required={required} infoPlacement={infoPlacement} info={info} showSettings={showSettings} onChangeSettings={onChangeLabelSettings} defaultSettings={defaultSettings} showAppliedSettings={showAppliedSettings} onClickSettings={onClickSettings} settingsValue={settingsValue} onDelete={onDelete}/>}
      <div id={id} className="toggle-main">
        {options.slice(0, 2).map((option, index) => (
          <div className={`toggle-option ${index === 0 ? 'left-option' : 'right-option'} ${activeToggleId === option.id ? "toggle-option-active" : "toggle-option-normal"}`}
            id={option.id}
            key={option.id}
            onClick={() => onClickToggle(option)}
          >
            {option?.icon && <img className='toggle-icon' src={option.icon} alt='info' />} {option.displayName}
          </div>
        ))}
      </div>
      {showValidationMessage && <ErrorText message={errorMessage} />}
    </div>
  );
};

export default Toggle;
