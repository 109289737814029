import React from "react";
import Chip from "../chip";

const Tag = ({ name, iconSrc, darkMode, color }) => {
  return (
    <div className="bimmatch-tag">
      <Chip name={name} iconSrc={iconSrc} darkMode={darkMode} color={color}/>
    </div>
  );
}

export default Tag