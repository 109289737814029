export const SUSTAINABILITY_PILLERS = [
  {
    id: "environmental",
    label: "Environmental",
    icon: "../../images/icon/bm-environmental.svg",
    greenIcon: "../../images/icon/bm-environmental-green.svg",
    blankIcon: "../../images/icon/bm-environmental-blank.svg",
    dbId: "66731f16-960d-49a7-93e3-0ee2345e616a",
  },
  {
    id: "economic",
    label: "Economic",
    icon: "../../images/icon/bm-economic.svg",
    greenIcon: "../../images/icon/bm-economic-green.svg",
    blankIcon: "../../images/icon/bm-economic-blank.svg",
    dbId: "42597f8b-7f79-4179-a8b3-635937d0f799",
  },
  {
    id: "social",
    label: "Social",
    icon: "../../images/icon/bm-social.svg",
    greenIcon: "../../images/icon/bm-social-green.svg",
    blankIcon: "../../images/icon/bm-social-blank.svg",
    dbId: "e69fe082-e6d9-4f84-b429-83bd364eb029",
  },
];
