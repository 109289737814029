import React, { useCallback, useEffect, useRef, useState } from 'react';
import Chip from '../chip';
import LabelBox from '../label-box';

const Chips = ({ chipsData = [], onSelect, value, defaultValue, hideLabel, ...labelProps }) => {

    // state
    const [selectedChips, setSelectedChips] = useState([])

    // ref
    const defaultValueStatus = useRef(false)

    // for value
    useEffect(() => {
        if (value) {
            setSelectedChips(value)
        }
    }, [value])

    // for defaultValue
    useEffect(() => {
        if (!value && !defaultValueStatus.current && defaultValue) {
            setSelectedChips(defaultValue)
        }
    }, [defaultValue, value])

    const onClickChip = useCallback((chipData) => {
        let updatedChips = [];
        let selectionFlag = true

        // unselect of chips (chips already exists in selected data)
        if (selectedChips.includes(chipData.id)) {
            updatedChips = selectedChips.filter((selectedChipId) => selectedChipId !== chipData.id);
            selectionFlag = false
        } else { //on select of chips
            updatedChips = [...selectedChips, chipData.id];
        }

        if (onSelect) {
            onSelect(updatedChips, chipData, selectionFlag)
        }
        setSelectedChips(updatedChips)
    }, [selectedChips, onSelect])

    return (
        <div className='chips'>
            {!hideLabel && (
                <LabelBox {...labelProps} />
            )}
            <div className='chips-container'>
                {chipsData.map(chipData => (
                    <Chip 
                        key={chipData.id}
                        darkMode={selectedChips.includes(chipData.id)}
                        iconSrc={chipData?.image || null} 
                        {...chipData} 
                        onClickChip={() => onClickChip(chipData)} 
                    />
                ))}
            </div>
        </div>

    );
}

export default Chips;