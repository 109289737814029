import React from 'react';
import InputFile from '../input-file';
import LabelBox from '../label-box';
import RepeatComponent from '../repeat-component';

const InputMultipleFiles = ({ label, onChange, defaultValue, onChangeStatus, uploadId, accept, validations, fieldName, shadow = false, max, showValidationMessage, uploadPath, fixedCount = 0, infoPlacement, info, withUniqueName, showSettings, defaultSettings, settingsValue, onChangeSettings, showAppliedSettings, onClickSettings, isError, errorMessage, onDelete, ...props }) => {

    return (
        <div className={`inputMultipleFiles ${shadow ? 'inputMultipleFiles-shadow' : ''}`}>
            {label && <LabelBox label={label} infoPlacement={infoPlacement} info={info} showSettings={showSettings} onChangeSettings={onChangeSettings} defaultSettings={defaultSettings} showAppliedSettings={showAppliedSettings} onClickSettings={onClickSettings} settingsValue={settingsValue} onDelete={onDelete}/>}
            <RepeatComponent
                {...props}
                onChange={onChange}
                defaultValue={defaultValue}
                max={max}
                fieldName={fieldName}
                fixedCount={fixedCount}
                removable={!fixedCount}
            
            >
                <InputFile hideLabel={true} accept={accept || []} validations={validations} uploadId={uploadId} onChangeStatus={onChangeStatus} showValidationMessage={showValidationMessage} uploadPath={uploadPath} withUniqueName={!!withUniqueName} isError={isError} errorMessage={errorMessage}/>
            </RepeatComponent>
        </div>
    );
}

export default InputMultipleFiles;