import React, { useCallback, useState } from "react";
import { getHighlightedText } from "../../utils";
import Popover from "../popover";

const Chip = ({
  name = "Chip",
  onClickChip,
  onClickCancel,
  withCross = false,
  darkMode = false,
  iconSrc,
  iconErrorSrc,
  highlightText,
  color = null,
  info = ''
}) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = useCallback(({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = iconErrorSrc;
    setImageError(true);
  }, [iconErrorSrc]);

  return (
    <div
      className={`chip ${!withCross ? "chip-normal" : "chip-cross"} ${darkMode ? "chip-dark" : ''} ${onClickChip ? "chip-clickable" : 'chip-unclickable'}`}
      onClick={onClickChip && onClickChip}
      style={color ? { backgroundColor: color } : null}
    >
      {(iconSrc && (!imageError || iconErrorSrc)) && (
        <img
          className="chip-icon"
          src={imageError ? iconErrorSrc : iconSrc}
          alt="icon"
          onError={handleImageError}
        />
      )}
      <div className="chip-name" title={name}>
        {highlightText ? getHighlightedText(name, highlightText) : name}
      </div>
      <img
        className="chip-cancel"
        src={"../../images/icon/cross-grey.svg"}
        alt="cancel"
        onClick={onClickCancel && onClickCancel}
      />

      {info && (
        <Popover
          content={
            <div className="chip-info-tooltip">
              {info}
            </div>
          }
          showOnHover={true}
          theme={'black'}
          placement={'right'}
        >
          <img
            className="chip-info"
            src={"../../images/icon/info-circle.svg"}
            alt="info"
          />
        </Popover>
      )}
    </div>
  )
};

export default Chip;
