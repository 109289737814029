import React, { useCallback , useState} from 'react'
import Maps from '../../common/map/Maps'
import CreateDistributor from '../create-distributor'
import RepeatComponent from '../repeat-component'
import CategorySelectMultiple from '../select-categories'

function CreateDistributorMultiple({ 
    defaultCategories,
    onSelectCategories,
    onClickSuggestUs,
    onChangeOfDistributorFields
}) {

    const [selectedLocations, setSelectedLocations] = useState([]);

    // on selecting and removing of location
    const onSelectLocation = useCallback((location, currentIndex) => {
        if (location) {
            // while adding distributor adding the location data to map
            selectedLocations[currentIndex] = location
        } else {
            // while removing distributor removing the location data from map
            selectedLocations.splice(currentIndex, 1)
        }
        setSelectedLocations([...selectedLocations])
    }, [selectedLocations])

    return (
        <div className='distributor-data-container'>
            <div className='categories-create-distributor'>
                <div className='categories'>
                    <CategorySelectMultiple
                        defaultCategories={defaultCategories}
                        onSelectCategories={onSelectCategories}
                        onClickSuggestUs={onClickSuggestUs}
                    />
                </div>
                <div className='create-distributor-repeat'>
                    <RepeatComponent alignment={'vertical'} onChange={onChangeOfDistributorFields} removable={true}>
                        <CreateDistributor onSelectLocation={onSelectLocation} />
                    </RepeatComponent>
                </div>
            </div>
            <div className='create-distributor-maps'>
                <div className='maps'>
                    <Maps selectedLocations={selectedLocations} />
                </div>
            </div>
        </div>
    )
}

export default CreateDistributorMultiple



