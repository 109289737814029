import React, { useCallback, useEffect, useRef, useState } from "react";
import { fieldValidation } from "../../utils";
import ErrorText from "../error-text";
import LabelBox from "../label-box";
import SelectLocation from "../select-location";

const Location = ({
  id,
  required = false,
  label,
  isError = false,
  errorMessage,
  hideLabel = false,
  type = "country",
  onSelect,
  fieldName,
  validations,
  showValidationMessage = false,
  infoPlacement,
  info,
  showSettings,
  onChangeSettings,
  defaultSettings,
  showAppliedSettings,
  onClickSettings,
  settingsValue,
  onDelete,
  ...props
}) => {

  const [hasError, setHasError] = useState(isError)
  const [hasErrorMessage, setHasErrorMessage] = useState(errorMessage)
  const settings = useRef(null)
  const fieldValue = useRef(null)

  //updating state of on change of error & message
  useEffect(() => {
    setHasError(isError)
  }, [isError])

  useEffect(() => {
    setHasErrorMessage(errorMessage)
  }, [errorMessage])

  const onSelectChips = useCallback(
    (flag, item, data) => {
      let formattedData = data
      if (type === "country") {
        formattedData = formattedData.map(country => country.id)
        const { isValid, message } = fieldValidation(validations ? { ...validations, type: 'select' } : { type: 'select' }, formattedData, label)
        if (!isValid) {
          if (!hasError) setHasError(true)
          if (message) setHasErrorMessage(message)
        } else {
          if (hasError) setHasError(false)
          if (hasErrorMessage) setHasErrorMessage('')
        }
      }
      if (fieldName) {
        const argData = { [fieldName]: formattedData }
        if (settings.current) argData.settings = settings.current
        fieldValue.current = argData
        onSelect && onSelect(argData, item, flag);

      } else {
        onSelect && onSelect(formattedData, item, flag);
      }
    }, [onSelect, fieldName, type, validations, hasErrorMessage, hasError, label, settings])

  const onChangeLabelSettings = useCallback(settingsData => {
    onChangeSettings && onChangeSettings(settingsData)
    settings.current = settingsData
    onSelect && onSelect(fieldValue.current ? { ...fieldValue.current, settings: settingsData } : { [fieldName]: null, settings: settingsData })
  }, [onChangeSettings, fieldValue, onSelect, fieldName])

  return (
    <div
      id={id}
      className={`location ${!hasError && !hasErrorMessage ? "location-normal" : "location-error"}`}
    >
      {!hideLabel && <LabelBox required={required} label={label} infoPlacement={infoPlacement} info={info} showSettings={showSettings} onChangeSettings={onChangeLabelSettings} defaultSettings={defaultSettings} showAppliedSettings={showAppliedSettings} onClickSettings={onClickSettings} settingsValue={settingsValue} onDelete={onDelete}/>}
      <SelectLocation type={'select'} width={"100%"} {...props} onSelectChips={onSelectChips} />
      {showValidationMessage && <ErrorText message={hasErrorMessage} />}
    </div>
  );
};

export default Location;
