import { useMutation } from "@apollo/client";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import Dropzone from "react-dropzone";
import { v4 as uuidv4 } from 'uuid'
import { UPLOAD_FILE } from "../../graphqlQueries";
import ErrorText from "../error-text";
import LabelBox from "../label-box";
import Toast from "../toast";

function checkValidations(data, validations) {
  let isValid = true
  let message = ''
  if (validations) {
    const { maxUploadFileSize, supportedFileTypes } = validations
    const { size, name } = data

    //validating file type
    if (supportedFileTypes && supportedFileTypes?.length) {
      const regex = new RegExp("[^.]+$");
      const extensionData = name.match(regex);
      const extension = extensionData && extensionData.length ? extensionData[0] : ''
      const extensionLowerCase = extension.toLowerCase()
      const isExtensionMatching = extension ? supportedFileTypes.includes(extensionLowerCase) : false

      //if uploaded file type doesn't matches supported types
      if (!isExtensionMatching) {
        isValid = false
        message = `Only ${supportedFileTypes.join(', ').toLocaleUpperCase()} supported.`
      }
    }

    if (isValid) {
      //validating file size
      const sizeInMiB = size / 1024 / 1024
      if (sizeInMiB > maxUploadFileSize) {
        isValid = false
        message = `Max size ${maxUploadFileSize}MB`
      }
    }
  }

  return {
    isValid,
    message
  }
}

const InputFile = ({
  id,
  required,
  label,
  errorMessage,
  isError = false,
  onRemove,
  fileName,
  removable = false,
  hideLabel = false,
  onUploadSuccess,
  onUploadError,
  onChange,
  onDragUpload,
  onChangeStatus,
  validations,
  fieldName,
  uploadId,
  isCustomRequest = false,
  defaultValue,
  showValidationMessage = false,
  accept,
  showRemoveImage,
  uploadPath,
  infoPlacement,
  info,
  withUniqueName = false,
  showSettings,
  onChangeSettings,
  defaultSettings,
  showAppliedSettings,
  onClickSettings,
  settingsValue,
  onDelete,
  ...props
}) => {
  const uuid = useMemo(() => uuidv4(), [])
  const [selectedFileName, setSelectedFileName] = useState("");
  const [previewImageFile, setPreviewImageFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [toasterData, setToasterData] = useState([])
  const [hasError, setHasError] = useState(isError)
  const [hasErrorMessage, setHasErrorMessage] = useState(errorMessage)
  const [defaultValueSetStatus, setDefaultValueSetStatus] = useState(false)
  const [isSuccessfullyUploaded, setIsSuccessfullyUploaded] = useState(false)

  const inputRef = useRef(null)
  const settings = useRef(null)
  // fieldValue is being used in case fieldName is passed
  const fieldValue = useRef(null)

  //API to upload file/image to bimmatch
  const [uploadFile] = useMutation(UPLOAD_FILE);

  //setting state of on change of error & message
  useEffect(() => {
    setHasError(isError)
  }, [isError])

  useEffect(() => {
    setHasErrorMessage(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    if (!defaultValueSetStatus && defaultValue && !selectedFileName) {
      const isImgageType = defaultValue?.type.includes('image')
      setDefaultValueSetStatus(true)
      setIsSuccessfullyUploaded(true)
      setSelectedFileName(defaultValue?.displayName)
      const defalutfieldValue = { [fieldName]: defaultValue }
      if (defaultSettings) defalutfieldValue.settings = defaultSettings
      fieldValue.current = defalutfieldValue
      if (isImgageType) setPreviewImageFile(defaultValue?.url)
    }
  }, [defaultValue, selectedFileName, defaultValueSetStatus, defaultSettings, fieldName])

  const getUniqueName = useCallback(
    (fileName) => {
      const index = fileName.lastIndexOf(".")
      return `${fileName.substring(0, index)}${uuid}${fileName.substring(index)}`
    },
    [uuid],
  )

  const uploadFileData = useCallback(_file => {
    const uniqueFileName = getUniqueName(_file.name);
    const file = withUniqueName ? new File([_file], uniqueFileName, { type: _file.type }) : _file;
    const { isValid, message } = checkValidations(file, validations)

    if (isSuccessfullyUploaded) setIsSuccessfullyUploaded(false)
    if (previewImageFile) setPreviewImageFile(null)

    const fileName = file.name.trim();
    setSelectedFileName(fileName);

    if (!isValid) {
      setHasError(true)
      setHasErrorMessage(message)
      const argData = fieldName ? { [fieldName]: null } : null
      onChange && onChange(argData)
      return
    } else {
      if (hasError) setHasError(false)
      if (hasErrorMessage) setHasErrorMessage('')
    }

    //checking file type to show appropriate data to ui
    if (file.type.includes('image')) {
      setPreviewImageFile(URL.createObjectURL(file))
    }

    if (!isCustomRequest && uploadId && file) {
      onChangeStatus && onChangeStatus('uploading')

      setIsUploading(true)
      const finalData = {
        id: uploadId,
        files: [file]
      }
      if (uploadPath) finalData.path = uploadPath
      uploadFile({
        variables: finalData
      }).then(({ data }) => {
        const uploadedData = data.uploadFile[0]
        if (uploadedData?.__typename) delete uploadedData.__typename
        const argData = fieldName ? { [fieldName]: uploadedData } : uploadedData
        onUploadSuccess && onUploadSuccess(argData)
        setIsSuccessfullyUploaded(true)
        // onchange will get triggered in place of original onchange if not a custom request
        if (!isCustomRequest && onChange) onChange(argData)

        onChangeStatus && onChangeStatus('success')
        setIsUploading(false)
      }).catch((error) => {
        onUploadError && onUploadError(error)
        onChangeStatus && onChangeStatus('error')
        setIsUploading(false)
        setHasError(true)
        setToasterData([{
          id: `input-file-toast-${uuid}`,
          title: 'Error',
          description: label ? `${label} upload failed. Please try again.` : 'Upload Failed. Please try again.',
          type: 'error'
        }])
      })
    }
  }, [
    label, uuid, onUploadSuccess, onUploadError, uploadId,
    uploadFile, previewImageFile, onChangeStatus,
    fieldName, isCustomRequest, onChange, validations,
    isSuccessfullyUploaded, hasError, hasErrorMessage, uploadPath,
    getUniqueName, withUniqueName
  ])

  const onUpload = useCallback(data => {
    const file = data.length ? data[0] : null
    const argData = fieldName ? { [fieldName]: file } : file
    if (file) {
      uploadFileData(file)
      onDragUpload && onDragUpload(argData, data)
    } else {
      onDragUpload && onDragUpload(null)
    }
  }, [uploadFileData, onDragUpload, fieldName]);


  const onChangeInput = useCallback(event => {
    const files = event?.target?.files;
    const file = files[0]
    let argData

    if (fieldName) {
      argData = { [fieldName]: file }
      if (settings.current) argData.settings = settings
      fieldValue.current = argData
    } else {
      argData = file
    }

    if (file) {
      uploadFileData(file)
      if (isCustomRequest && onChange) onChange(argData, event)
    } else {
      if (isCustomRequest && onChange) onChange(null, event)
    }
  }, [uploadFileData, onChange, fieldName, isCustomRequest, settings]);

  const onRemoveInput = useCallback(event => {
    event.stopPropagation()
    event.preventDefault()
    onRemove && onRemove()
  }, [onRemove])

  const onRemoveImage = useCallback(event => {
    event.stopPropagation()
    event.preventDefault()
    inputRef.current.value = ""
    const argData = fieldName ? { [fieldName]: null } : null
    onChange && onChange(argData)
    if (selectedFileName) setSelectedFileName('')
    if (previewImageFile) setPreviewImageFile(null)
    if (hasError) setHasError(false)
    if (hasErrorMessage) setHasErrorMessage('')
    if (isSuccessfullyUploaded) setIsSuccessfullyUploaded(false)
  }, [selectedFileName, previewImageFile, hasError, hasErrorMessage, isSuccessfullyUploaded, fieldName, onChange])

  const fileNameToShow = fileName || selectedFileName;

  const fieldValidation = validations?.supportedFileTypes || accept;

  const onChangeLabelSettings = useCallback(settingsData => {
    onChangeSettings && onChangeSettings(settingsData)
    settings.current = settingsData
    onChange && onChange(fieldValue.current ? { ...fieldValue.current, settings: settingsData } : { [fieldName]: null, settings: settingsData })
  }, [onChangeSettings, fieldValue, onChange, fieldName])

  return (
    <div
      className={`input-file ${!hasError ? "input-file-normal" : "input-file-error"} ${isUploading ? 'input-file-loading' : ''}`}
    >
      {!hideLabel && <LabelBox required={required} label={label} infoPlacement={infoPlacement} info={info} showSettings={showSettings} onChangeSettings={onChangeLabelSettings} defaultSettings={defaultSettings} showAppliedSettings={showAppliedSettings} onClickSettings={onClickSettings} settingsValue={settingsValue} onDelete={onDelete}/>}
      <Dropzone
        onDrop={onUpload}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="input-file-container">
            <label {...getRootProps()} for={uuid}>
              <div className="input-file-label">
                <div className='file-details'>
                  {(isSuccessfullyUploaded || (showRemoveImage && selectedFileName)) && <div className={`file-action ${showRemoveImage ? 'file-action-remove' : ''}`} onClick={onRemoveImage}></div>}
                  <p className="file-name">
                    {fileNameToShow && fileNameToShow.length > 17 ? (
                      <>
                        <span className='start-name'>{fileNameToShow}</span>
                        <span className='end-name'>{fileNameToShow}</span>
                      </>
                    ) : (
                      fileNameToShow ? <span>{fileNameToShow}</span> : "File Name"
                    )}
                  </p>
                  {!fileNameToShow && <p className="label-des">Drop or click here to upload{validations?.maxUploadFileSize ? ` (Upto ${validations?.maxUploadFileSize}mb)` : ""}</p>}
                </div>

                {removable && !isUploading && (
                  <img
                    onClick={onRemoveInput}
                    className="remove"
                    src="/images/icon/close.svg"
                    alt="remove"
                  />
                )}
                {previewImageFile && <img className='preview-img' alt='preview' src={previewImageFile} />}
              </div>


            </label>
            <input
              {...props}
              ref={inputRef}
              onChange={onChangeInput}
              id={uuid}
              data-id={id}
              type="file"
              disabled={isUploading}
              accept={fieldValidation && fieldValidation?.length ? `.${fieldValidation.join(',.')}` : ''}
            />
          </div>
        )}
      </Dropzone>

      {showValidationMessage && <ErrorText message={hasErrorMessage} />}
      <Toast toastList={toasterData} position={'top-right'} autoDeleteTime={3000} />
    </div>
  );
};

export default InputFile;
