import React, { useState, forwardRef, useCallback } from "react";
import { Alert } from "../../../components";
import { AlertContext } from ".";

const AlertContextWithProvider = forwardRef(({ children }, ref) => {
    const [alerts, setAlerts] = useState([])

    const updateAlerts = useCallback((newAlerts) => {
        setAlerts(newAlerts);
    }, []);

    const removeAlerts = useCallback((ids = []) => {
        setAlerts(alerts.filter(alert => !ids.includes(alert.id)));
    }, [alerts]);

    const removeAlertsByTags = useCallback((tags = []) => {
        setAlerts(alerts.filter(alert => !tags.includes(alert?.tag)));
    }, [alerts]);

    const addAlert = useCallback((alertData) => {
        setAlerts(oldState => ([...oldState, alertData]))
    }, []);

    const upsertAlert = useCallback((id, alertData) => {
        setAlerts(oldState => {
            if (oldState.find(alert => alert.id === id)) {
                return oldState.map(alert => alert.id === id ? { ...alert, ...alertData } : alert)
            } else {
                return [...oldState, { ...alertData, id }]
            }
        })
    }, []);

    const upsertAlerts = useCallback((upsertData, tag) => {
        setAlerts(oldState => {
            const newState = oldState.filter(alert => alert.tag !== tag)

            upsertData.forEach(({ id, alertData }) => {
                newState.push({
                    ...alertData,
                    id,
                    tag
                })
            })
            return newState
        })
    }, [])


    return (
        <AlertContext.Provider value={{ updateAlerts, removeAlerts, addAlert, upsertAlert, removeAlertsByTags, upsertAlerts }}>
            <Alerts alerts={alerts} ref={ref} />
            {children}
        </AlertContext.Provider>
    )
})

const Alerts = forwardRef(({ alerts }, ref) => {
    return (
        <div className="alerts-wrapper" ref={ref}>
            <div className="container">
                {alerts.map(alertData => <Alert key={alertData.id} {...alertData} />)}
            </div>
        </div>
    )
})

export default AlertContextWithProvider;