import React from 'react'
import Popover from '../popover'
import PopoverMenuContent from '../popover-menu-content'
import Chip from '../chip'

const ListItem = ({ type = "primary", iconSrc, onIconClick, optionMenuItems, title, className, tags, username, updatedAt, renderRightSection, ...props }) => {
    return (
        <div className={`bimmatch-list-item item-type-${type} ${className || ""}`} {...props}>
            {iconSrc && <img src={iconSrc} alt='icon' className='list-item-icon' onClick={onIconClick} />}
            <div className='left-section'>
                <div className='item-title-container'>
                    {typeof title === 'string' ? (
                        <h3 className='item-title'>{title}</h3>
                    ) : (
                        title ? <div className='item-title'>{title}</div> : <></>
                    )}
                </div>
                {tags && (
                    <div className='list-item-tags'>
                        {tags.map(tagData => <Chip key={tagData.id} name={tagData.displayName} />)}
                    </div>
                )}
            </div>
            <div className='right-section'>
                {username && <p className='item-username truncate-text'>{username}</p>}
                {updatedAt && <p className='item-updated-at truncate-text'>{updatedAt}</p>}
                {renderRightSection && renderRightSection}
            </div>
            {optionMenuItems && (
                <div className='options'>
                    <Popover
                        content={<PopoverMenuContent items={optionMenuItems} />}
                        hideArrow={true}
                        placement="bottom"
                    >
                        <img className="bimmatch-three-dot" src="/images/icon/threedots.svg" alt="icon" />
                    </Popover>
                </div>
            )}
        </div>
    )
}

export default ListItem