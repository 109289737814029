import React, { isValidElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import BadgeNumber from "../badge-counter";
import ConditionalWrapper from "../conditional-wrapper";

const Tabs = ({ items, defaultValue, value, onChange }) => {
    const mountedRef = useRef();
    const [activeTab, setActiveTab] = useState();

    const activeTabData = useMemo(() => items.find(item => item.id === activeTab), [items, activeTab])

    const handleChangeTab = useCallback((tabId) => () => {
        if (onChange) {
            onChange(tabId)
        }
        setActiveTab(tabId);
    }, [onChange])

    const isValidTabId = useCallback((id) => {
        return items.find(item => item.id === id)
    }, [items])

    useEffect(() => {
        setActiveTab(isValidTabId(value) ? value : items?.[0]?.id);
        if (mountedRef.current) return;

        //will run only once
        mountedRef.current = true;
        !value && setActiveTab(defaultValue ? defaultValue : ((Array.isArray(items) && items.length) ? items[0].id : null));
    }, [defaultValue, value, items, isValidTabId])

    return (
        <>
            <ul className="menu-list link bimmatch-tabs">
                {
                    items.map(tab => <TabHeader key={tab.id} {...tab} active={activeTab === tab.id} onClick={handleChangeTab(tab.id)} />)
                }
            </ul>
            {(activeTabData && isValidElement(activeTabData.children)) && activeTabData.children}
        </>
    );
};

const TabHeader = ({ render, label, active, onClick, src, count }) => {
    return (
        <li className={`tab-item ${(active) ? "selected" : ""}`} onClick={onClick}>
            <ConditionalWrapper
                condition={!!src}
                Wrapper={<TabHeaderWithImageWrapper src={src} />}
            >
                {isValidElement(render) ? render : label}
                {(!isNaN(count)) && <BadgeNumber count={count} />}
            </ConditionalWrapper>
        </li>
    )
}

const TabHeaderWithImageWrapper = ({ children, src }) => (
    <div className='title-with-images'>
        <img className='tab-icon' src={src} alt={src} />
        {children}
    </div>
)

export default Tabs;
