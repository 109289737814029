import React, { useCallback, useEffect, useRef, useState } from "react";
import { fieldValidation } from "../../utils";
import ErrorText from "../error-text";
import LabelBox from "../label-box";

const InputTextarea = ({
  id,
  required = false,
  placeholder = "Type",
  label,
  isError = false,
  errorMessage,
  maxlength = 250,
  hideLabel = false,
  fieldName,
  onChange,
  defaultValue,
  validations,
  showValidationMessage = false,
  infoPlacement,
  info,
  showSettings,
  onChangeSettings,
  defaultSettings,
  showAppliedSettings,
  onClickSettings,
  settingsValue,
  onDelete,
  applyTrim = false,
  ...props
}) => {
  const defaultValueLength = defaultValue ? defaultValue.length : 0
  const [letterCount, setLetterCount] = useState(defaultValueLength)
  const [hasError, setHasError] = useState(isError)
  const [hasErrorMessage, setHasErrorMessage] = useState(errorMessage)
  const settings = useRef(null)
  // fieldValue is being used in case fieldName is passed
  const fieldValue = useRef(null)

  // updating state of on change of error & message
  useEffect(() => {
    setHasError(isError)
  }, [isError])

  useEffect(() => {
    setHasErrorMessage(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    if (defaultValue && !fieldValue.current) {
      const defaultFieldValue = {
        [fieldName]: defaultValue
      }

      if (defaultSettings) defaultFieldValue.settings = defaultSettings
      fieldValue.current = defaultFieldValue
    }
  }, [defaultSettings, defaultValue, fieldName])

  const onChangeInput = useCallback(
    (event) => {

      let value = event.target.value

      if (applyTrim) {
        value = value.trim();
      }
      
      const { isValid, message } = fieldValidation(validations ? { type: 'string', max: maxlength, ...validations } : { type: 'string', max: maxlength }, value, label)

      if (!isValid) {
        if (!hasError) setHasError(true)
        if (message) setHasErrorMessage(message)
      } else {
        if (hasError) setHasError(false)
        if (hasErrorMessage) setHasErrorMessage('')
      }

      setLetterCount(value.length);
      if (fieldName) {
        const argData = {
          [fieldName]: value,
        }
        if (settings?.current) argData.settings = settings.current
        fieldValue.current = argData
        onChange && onChange(argData, event);
      } else {
        onChange && onChange(value, event);
      }
    },
    [fieldName, onChange, hasError, hasErrorMessage, validations, maxlength, label, settings, applyTrim]
  );

  const onChangeLabelSettings = useCallback(settingsData => {
    onChangeSettings && onChangeSettings(settingsData)
    settings.current = settingsData
    onChange && onChange(fieldValue.current ? { ...fieldValue.current, settings: settingsData } : { [fieldName]: null, settings: settingsData })
  }, [onChangeSettings, fieldValue, onChange, fieldName])

  return (
    <div className={`input-textarea ${!hasError && !hasErrorMessage ? "input-textarea-normal" : "input-textarea-error"}`}>
      {!hideLabel && <LabelBox required={required} label={label} infoPlacement={infoPlacement} info={info} showSettings={showSettings} onChangeSettings={onChangeLabelSettings} defaultSettings={defaultSettings} showAppliedSettings={showAppliedSettings} onClickSettings={onClickSettings} settingsValue={settingsValue} onDelete={onDelete}/>}
      <textarea
        {...props}
        defaultValue={defaultValue}
        id={id}
        placeholder={placeholder}
        onChange={onChangeInput}
        dir='auto'
      />
      <ErrorText
        isError={!(letterCount <= (validations?.max || maxlength))}
        message={
          !letterCount
            ? `Up to ${(validations?.max || maxlength)} characters`
            : `${letterCount}/${(validations?.max || maxlength)} characters`
        }
      />
      {showValidationMessage && <ErrorText message={hasErrorMessage} />}
    </div>
  );
};

export default InputTextarea;
