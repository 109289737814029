import axios from "axios";
import { useEffect, useState } from "react";
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from "../utils";

export default function useCountries({ countriesValue = [], countryPriority } = {}) {
  const [allCountriesData, setAllCountriesData] = useState(countriesValue);

  useEffect(() => {
    async function getAllCountries() {
      try {
        let countriesData = getLocalStorageWithExpiry("countriesData");
        if (!countriesData) {
          const { data } = await axios.get("https://restcountries.com/v3.1/all?fields=name,cca2,flags");
          countriesData = data;
          setLocalStorageWithExpiry("countriesData", data, 1440);
        }

        let formattedData = [];
        if (countriesData?.length) {
          formattedData = countriesData.map((country) => {
            const { name, flags, cca2 } = country;
            return {
              ...country,
              displayName: name.common.toLocaleUpperCase(),
              image: flags.svg || flags.svg || "",
              id: cca2,
              short_name: cca2,
            };
          })
            .sort((a, b) => (a.displayName > b.displayName) ? 1 : (b.displayName > a.displayName ? -1 : 0));
        }

        if (countriesData && formattedData.length) {
          //Displays priority country on top of the list
          if (countryPriority) {
            const priorityCountryData = formattedData.find((countryData) => countryData.id === countryPriority);

            if (priorityCountryData) {
              const priorityCountryIndex = formattedData.findIndex((countryData) => countryData.id === countryPriority);
              formattedData.splice(priorityCountryIndex, 1);
              formattedData.unshift(priorityCountryData);
            }
          }
          setAllCountriesData(formattedData);
        }
      } catch (error) {
        console.log("Error in countries api");
      }
    }

    if (!allCountriesData.length) getAllCountries();
  }, [allCountriesData, countryPriority]);

  return allCountriesData;
}
