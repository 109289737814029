import React from 'react';

const NoDataView = ({ noDataText = 'No data' }) => {
    return (
        <div className='no-data-view'>
            <img className='no-data-img' src='../../images/icon/building-crane.svg' alt='no-data' />
            <p className='no-data-text'>{noDataText}</p>
        </div>
    )
}

export default NoDataView