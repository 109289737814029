import React from "react";
import Modal from "../modal";
import { commonSuggestUsUrl } from "../../utils";

export default function ContactBimmatch({ visible = false, title, ...props }) {

  return (
    <div className="contact-bimmatch">
      <Modal
        visible={visible}
        withFooter={false}
        size="large"
        title={title || 'Your next best decision'}
        {...props}
      >
        <div className="contact-bimmatch-container">
          <p className="list-title">Create and manage your project on Bimmatch and get these:</p>
          <ul className="lists">
            {premimumListData.map((list, listIndex) => (<li className='list' key={'pointer-' + listIndex}>{list}</li>))}
          </ul>
          <div className="contact-bimmatch-footer">
            <p className="footer-text">For more information and a quick demo, contact us: <a className='suggest-us' href={commonSuggestUsUrl('Hi Bimmatch, contact me for a quick demo :)', 'contact@bimmatch.com')}>contact@bimmatch.com</a></p>
            <img className="footer-icon" src={'/images/icon/match.svg'} alt='contact-match'/>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const premimumListData = ['Managing and sharing your organization database', 'Converting Specs files to BIM by one click only', 'Generating automatically Bill Of Materials', 'Analyzing project compliance with your organization standard', 'Analyzing carbon emissions', 'Apply real products to your Bill Of Materials', 'Bimmatch is fully integrated with Autodesk tools, your data is synced']


