import React from 'react';
import Modal from '../modal';

const ImagePreview = ({ src, alt, onClose, title = '', visible, footerContent }) => {
    return (
        <div className='image-preview'>
            <Modal onClose={onClose} visible={visible} size='large' withFooter={false} title={title}>
                <img className='image-preview-image' src={src} alt={alt || 'image-preview'} />
                {footerContent && React.isValidElement(footerContent) && footerContent}
            </Modal>
        </div>
    );
}
export default ImagePreview;