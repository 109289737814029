import React, { useCallback, useMemo } from 'react'

const CustomAnchor = ({ children, onClick, href, target, disabled = false }) => {

    const anchorClass = useMemo(() => `bimmatch-anchor-tag ${disabled ? 'bimmatch-anchor-disabled' : 'bimmatch-anchor-normal'}`, [disabled])

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick()
        }
    }, [onClick])

    return (
        <>
            {!!href ? <a className={anchorClass} href={href} target={target}>{children}</a> : <span className={anchorClass} onClick={handleClick}>{children}</span>}
        </>
    )
}

export default CustomAnchor