import { COLOR } from "../../constants/color";
import { cmsStaticPropertyIds } from "../../utils";

export const TYPES = {
    COUNTRIES: "countries",
    SUSTAINABILITY_PILLARS: "sustainabilityPillars",
    BRANDS_AND_DISTRIBUTORS: "brandsAndDistributors",
    BIM_FILE: "bimFile",
    TEXT: "text",
    NAME: "name",
    DESCRIPTION: "description",
    CATEGORIES: "categories",
    GWP: "gwp",
    PROPERTY_KEYS: "propertyKeys",
    PROPERTY_WITH_VALUES: "propertyWithValues",
    ORGANIZATIONS: "organizations",
    DISCPLINES: "disciplines",
    MATERIALS: "materials",
    PIRS: "houses",
    TAGS: "tags",
    IMPORTANCE_RANKS: "importanceRanks",
    LOD: "lod",
    KEY_WORDS: "keywords",
    DESIGN_STAGE: "designStage",
    BUILDING_CODES: "buildingCodes",
    ROLES: "roles",
    DESIGN_AREA: "designArea",
    TYPE: "type",
    STANDARDS: "standards"
};

function getDefaultForParameters(defaultValue, forDefaultState = false) {

    const intialValue = Object.values(TYPES).reduce((acc, item) => {
        acc[item] = defaultValue
        return acc
    }, {})

    let finalValue = { ...intialValue, [TYPES.NAME]: true }

    if (forDefaultState) {
        finalValue = { ...intialValue, [TYPES.TEXT]: '',  [TYPES.NAME]: '', [TYPES.DESCRIPTION]: '', [TYPES.GWP]: false, [TYPES.BIM_FILE]: false }
    }
    return finalValue
}

export const defaultVisbileParameters = getDefaultForParameters(false)

export const defaultMatchBarState = getDefaultForParameters([], true)

export const supportedFields = [
    {
        id: TYPES.CATEGORIES,
        label: "Categories",
        subType: "multiSelectionWithTree",
        validations : { multiple: true, parentSelectable: true },
        color: COLOR.PALE_YELLOW
    },
    {
        id: TYPES.TEXT,
        label: "Search Text",
        subType: "text",
        placeholder: "Search by name/description",
        applyTrim: false
    },
    {
        id: TYPES.NAME,
        label: "Name",
        subType: "text",
        placeholder: "Search by Name",
        applyTrim: false
    },
    {
        id: TYPES.DESCRIPTION,
        label: "Description",
        subType: "text",
        placeholder: "Search by Description",
        applyTrim: false
    },
    {
        id: TYPES.ORGANIZATIONS,
        label: "Organization Libraries",
        subType: "multiselect"
    },
    {
        id: TYPES.DISCPLINES,
        label: "Disciplines",
        subType: "multiselect",
        placeholder: "Select Disciplines"
    },
    {
        id: TYPES.PIRS,
        label: "Projects specific requirements (PIR)",
        subType: "multiselect",
        placeholder: "Select Projects"
    },
    {
        id: TYPES.TAGS,
        label: "Search-tags",
        subType: "multiselect",
        placeholder: "Select Tags"
    },
    {
        id: TYPES.COUNTRIES,
        label: "Distributor Locations",
        subType: "multiselect",
        placeholder: "Select Locations"
    },
    {
        id: TYPES.BRANDS_AND_DISTRIBUTORS,
        label: "Brands And Distributors",
        subType: "multiSelectionWithSection"
    },
    {
        id: TYPES.PROPERTY_KEYS,
        label: "Parameters with values",
        subType: "multiSelectionWithSection"
    },
    {
        id: TYPES.PROPERTY_WITH_VALUES,
        label: "Values",
        subType: "multiSelectionWithSection",
        parent: TYPES.PROPERTY_KEYS,
        enabledWhenParentSelected: true,
        disbledPlaceholder: "To enable select Paramters with values first"
    },
    {
        id: TYPES.SUSTAINABILITY_PILLARS,
        label: "Sort by possible compliance with sustainability pillars",
        subType: "chips"
    },
    {
        id: TYPES.BIM_FILE,
        label: "Must have BIM files",
        subType: "switch",
        chipName: "Bim File"
    },
    {
        id: TYPES.GWP,
        label: "Must have GHG value",
        subType: "switch",
        chipName: "GHG value"
    },
    {
        id: TYPES.IMPORTANCE_RANKS,
        label: "Importance rank",
        subType: "chips"
    },
    {
        id: TYPES.LOD,
        label: "LOD",
        subType: "chips"
    },
    {
        id: TYPES.KEY_WORDS,
        label: "Key words",
        subType: "multiselect",
        placeholder: "Type to search",
        validations : { optionsVisibleOnSearch: true }
    },
    {
        id: TYPES.MATERIALS,
        label: "Materials",
        subType: "multiSelectionWithTree",
        placeholder: "Select Materials",
        validations : { multiple: true, parentSelectable: true, maxVisibleBranches: 2, unselectable: [2] },
        color: COLOR.PALE_BLUE
    },
    {
        id: TYPES.DESIGN_STAGE,
        label: "Design Stage",
        subType: "multiselect"
    },
    {
        id: TYPES.TYPE,
        label: "Type",
        subType: "multiselect"
    },
    {
        id: TYPES.ROLES,
        label: "Role",
        subType: "multiselect"
    },
    {
        id: TYPES.DESIGN_AREA,
        label: "Design Area",
        subType: "multiselect"
    },
    {
        id: TYPES.BUILDING_CODES,
        label: "Building Codes",
        subType: "multiselect"
    },
    {
        id: TYPES.STANDARDS,
        label: "Standards",
        subType: "multiselect",
        color: COLOR.PALE_GREEN
    },
]

export const supportedFieldsKeyMapped = supportedFields.reduce((acc, paramaterData) => {
    acc[paramaterData.id] = paramaterData
    return acc
}, {})

const toModifyTypeOfFields = [TYPES.DISCPLINES, TYPES.TAGS]

// include field types here that does not needed to be populated via as normal dynamic fields
const fieldsToExclude = []

export const getFields = (visibileParameters, parameterSettings = {}) => {
    let fieldsToDisplay = []

    if (!visibileParameters || !Object.keys(visibileParameters).length) {
        return fieldsToDisplay
    }

    supportedFields.forEach(fieldData => {
        if (!fieldsToExclude.includes(fieldData.id) && visibileParameters[fieldData.id]) {

            let fieldDataToDisplay = { ...fieldData }

            if (parameterSettings[fieldData.id]) {
                fieldDataToDisplay = {
                    ...fieldDataToDisplay,
                    ...parameterSettings[fieldData.id]
                }
            }

            if (visibileParameters?.[TYPES.ORGANIZATIONS] && toModifyTypeOfFields.includes(fieldData.id)) {
                fieldDataToDisplay = { ...fieldDataToDisplay, subType: "multiSelectionWithSection" }
            }

            fieldsToDisplay.push({
                ...fieldDataToDisplay,
                id: fieldData.id
            })
        }
    })

    return fieldsToDisplay
}

// these are fields those comes under properties data which we send in output
// Note: PROPERTY_KEYS & PROPERTY_WITH_VALUES are kept exception and handled seperately
export const fieldsUnderProperties = [
    {
        type: TYPES.DISCPLINES,
        id: cmsStaticPropertyIds.disciplines
    },
    {
        type: TYPES.TAGS,
        id: cmsStaticPropertyIds.searchTags
    },
    {
        type: TYPES.MATERIALS,
        id: cmsStaticPropertyIds.materials
    }
]

// static options
export const sustainabilityPillarsOptions = [
    {
        id: "66731f16-960d-49a7-93e3-0ee2345e616a",
        name: "Environmental",
        image: "../../images/icon/bm-environmental.svg"
    },
    {
        id: "42597f8b-7f79-4179-a8b3-635937d0f799",
        name: "Economic",
        image: "../../images/icon/bm-economic.svg"
    },
    {
        id: "e69fe082-e6d9-4f84-b429-83bd364eb029",
        name: "Social",
        image: "../../images/icon/bm-social.svg"
    },
];

export const importanceRanksOptions = [
    {
        id: "High",
        name: "High",
        image: "../../images/icon/exclamation-danger.svg"
    },
    {
        id: "Mid",
        name: "Mid",
        image: "../../images/icon/exclamation-warning.svg"
    },
    {
        id: "Low",
        name: "Low",
        image: "../../images/icon/exclamation-info.svg"
    }
]

export const lodOptions = [
    {
        id: "100",
        name: "100"
    },
    {
        id: "200",
        name: "200"
    },
    {
        id: "300",
        name: "300"
    },
    {
        id: "400",
        name: "400"
    },
    {
        id: "500",
        name: "500"
    },
]
