import React, { useCallback, useRef, useState } from 'react'
import Header from './Header';
import AlertContextWithProvider from '../contexts/alert';
import { useResizeObserver } from '../../hooks';

const BaseLayout = ({ children, className = "white-header" }) => {
    const alertsWrapperRef = useRef(null)
    const [isLeftMenuOpen, setLeftMenuOpen] = useState(false);
    const [alertsHeight, setAlertsHeight] = useState(0)

    useResizeObserver(alertsWrapperRef.current, (alertsDomReact) => {
        setAlertsHeight(alertsDomReact.height)
    })

    const handleLeftMenuOpen = useCallback((bool) => {
        setLeftMenuOpen(bool);
    }, [])

    return (
        <div className={`base-layout-wrapper ${isLeftMenuOpen ? "pushmenu-push-toright" : ""}`}>
            <Header
                className={className}
                isLeftMenuOpen={isLeftMenuOpen}
                handleLeftMenuOpenCallback={handleLeftMenuOpen}
            />
            <AlertContextWithProvider ref={alertsWrapperRef}>
                <div className='page-content' style={{ paddingTop: `${alertsHeight + headerHeight}px` }}>
                    {children}
                </div>
            </AlertContextWithProvider>
        </div>
    )
}

const headerHeight = 70;

export default BaseLayout