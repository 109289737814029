export const COLOR = {
    DARK_GRAY: "#cccccc",
    WHITE: "#ffffff",
    SKY_BLUE: "#379BBA",
    LIGHT_GREEN: "#73BA37",
    BLUE_HIGHLIGHT: "#376BBA",

    // colors used for theming matchbar chips
    PALE_YELLOW : "#fff2b2",
    PALE_BLUE: "#bbedff",
    PALE_GREEN: "#d9f2e0"
}