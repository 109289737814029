import React, { useContext } from "react";
import AlertContextWithProvider from "./context-with-provider"

export const AlertContext = React.createContext({
    updateAlerts: (alertsData) => { },
    removeAlerts: (ids) => { },
    addAlert: (alertData) => { },
    upsertAlert: (id, alertData) => { },
    removeAlertsByTags: (tags) => { },
    upsertAlerts: (alertsWithId, tag) => { }
});

export const useAlerts = () => {
    return useContext(AlertContext);
};

export default AlertContextWithProvider;