import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { getHighlightedText } from '../../utils';

const mainBranchTypes = ['figure', 'table']
const allBranchTypes = ['figure', 'table', 'button']

const Tree = ({ treeData = [], selectable = true, size = 'large', onBranchSelect, defaultValue, parentSelectable = false, value, filterText = '', max = Infinity, unSelectableBranches = [], multiple = false, searchMode = null, maxVisibleBranches = Infinity, ...props }) => {

    // local states
    const [selectedBranches, setSelectedBranches] = useState([])
    const [defaultValueSetStatus, setDefaultValueSetStatus] = useState(false)

    useEffect(() => {
        if (defaultValue && !defaultValueSetStatus) {
            setSelectedBranches(multiple ? defaultValue : [defaultValue])
            setDefaultValueSetStatus(true)
        }
    }, [defaultValue, defaultValueSetStatus, multiple])

    useEffect(() => {
        if (value) {
            setSelectedBranches(multiple ? value : [value])
        }
    }, [value, multiple])

    const onClickBranch = useCallback((branchData) => {

        let updatedSelectedBranchesData = [...selectedBranches]
        let updatedBranchSelectedStatus = false
        const branchId = branchData.id

        if (!selectedBranches.includes(branchId)) { // when selecting a branch
            if (multiple) {
                updatedSelectedBranchesData.push(branchId)
            } else {
                updatedSelectedBranchesData = [branchId]
            }
            updatedBranchSelectedStatus = true
        } else { //when unselecting a branch
            //removing unselected branch id from selected list
            if (multiple) {
                const unselectedBranchIndex = selectedBranches.indexOf(branchId);
                if (unselectedBranchIndex > -1) {
                    updatedSelectedBranchesData.splice(unselectedBranchIndex, 1);
                }
            } else {
                updatedSelectedBranchesData = []
            }

            updatedBranchSelectedStatus = false
        }

        // cuurently if branch type is passed then we don't consider it as tree with selection
        if (allBranchTypes.includes(branchData?.type)) {
            onBranchSelect && onBranchSelect(branchData)
            return
        }

        if (allBranchTypes.includes(branchData?.type) || (selectable && (branchData.isParent ? parentSelectable : true) && (multiple ? (updatedSelectedBranchesData?.length <= max) : true) && !unSelectableBranches.includes(branchData.branchDepth))) {
            setSelectedBranches(updatedSelectedBranchesData)
            onBranchSelect && onBranchSelect(updatedBranchSelectedStatus, branchData, updatedSelectedBranchesData)
        }



    }, [selectedBranches, selectable, parentSelectable, onBranchSelect, max, unSelectableBranches, multiple])

    const finalTreeData = useMemo(() => filterText ? filterTree(treeData, filterText) : treeData, [treeData, filterText])

    return (
        <div className={`tree-container ${size === 'large' ? 'tree-container-large' : 'tree-container-small'}`}>
            {finalTreeData.map(branchData => <Branch  {...props} key={branchData.id} branchData={branchData} selectable={selectable} onBranchSelect={onClickBranch} isParent={true} parentSelectable={parentSelectable} selectedBranches={selectedBranches} filterText={filterText} branchDepth={1} unSelectableBranches={unSelectableBranches} searchMode={searchMode} maxVisibleBranches={maxVisibleBranches} {...props}/>)}
        </div>
    );
}

const Branch = ({ branchData, selectable, onBranchSelect, isParent, parentSelectable, selectedBranches, filterText, branchDepth, unSelectableBranches, branchButtonName = '', branchButtonLogo, onBranchButtonClick, loadData, searchMode = null, maxVisibleBranches, defaultOpenBranches = [], ...props }) => {

    // local states
    const [isBranchOpen, setIsBranchOpen] = useState(null)
    const [isBranchLoading, setIsBranchLoading] = useState(false)

    // memos
    const childrens = useMemo(() => branchData?.children || [], [branchData])

    const isFilterText = useMemo(() => {
        return ((filterText && filterText.length >= 3) || null)
    }, [filterText])

    const branchOpen = useMemo(() => ((isBranchOpen ?? isFilterText) ?? ((searchMode || defaultOpenBranches.includes(branchDepth)) && childrens.length)), [isBranchOpen, isFilterText, searchMode, childrens, branchDepth, defaultOpenBranches])

    const visibilityOfToggleArrow = useMemo(() => (!isBranchLoading && (branchDepth < maxVisibleBranches) && ((loadData && !branchData?.isLeaf) || (!!childrens.length || mainBranchTypes.includes(branchData?.type)))), [branchData, branchDepth, childrens.length, isBranchLoading, loadData, maxVisibleBranches])

    // this function will be called when loadData function has been passed
    const asyncLoad = useCallback((branchData) => {
        setIsBranchLoading(true)

        function rejectCallback() {
            setIsBranchLoading(false)
        }
        function resolveCallback() {
            rejectCallback()
            setIsBranchOpen(true)
        }

        // loadData should return promise
        // promise reject will also disable branch loader for now
        loadData(branchData, branchDepth).then(resolveCallback, rejectCallback)
    }, [loadData, branchDepth])

    const onToggleBranch = useCallback(() => {
        // to not do anything in case of image & table branch type
        if (mainBranchTypes.includes(branchData?.type)) {
            return
        }

        // if loadData is passed then we will run async function to resolve this promise
        // will not run asyncLoad if parent branch is already open or if children already persists
        if (loadData && !isBranchOpen && !childrens.length) {
            asyncLoad(branchData)
            return
        }
        setIsBranchOpen(oldState => !(oldState ?? isFilterText ?? branchOpen))
    }, [isFilterText, branchData, loadData, asyncLoad, isBranchOpen, childrens, branchOpen])

    const onClickBranch = useCallback(branchData => {
        onBranchSelect && onBranchSelect({ ...branchData, isParent, branchDepth })
    }, [onBranchSelect, isParent, branchDepth])

    const onClickBranchButton = useCallback((event) => {
        event.stopPropagation()
        onBranchButtonClick && onBranchButtonClick()
    }, [onBranchButtonClick])

    if (!(branchDepth <= maxVisibleBranches)) {
        return <></>
    }

    return (
        <div className='branch-container'>
            <div className={`branch-main ${branchOpen ? 'branch-open' : 'branch-close'} ${selectedBranches.includes(branchData.id) ? 'branch-selected' : 'branch-unselected'} ${parentSelectable ? 'parent-selectable' : 'parent-unselectable'} ${isParent ? 'branch-parent' : 'branch-child'} ${(selectable && !unSelectableBranches.includes(branchDepth)) ? 'row-selectable' : 'row-unselectable'} ${!!branchData?.type ? ('branch-type-' + branchData.type) : 'branch-type-default'}`}>
                {visibilityOfToggleArrow ? (
                    <img 
                        className={`branch-icon ${((branchData?.type && mainBranchTypes.includes(branchData.type)) ? `branch-icon-${branchData.type}` : 'branch-icon-arrow')}`} 
                        src={`/images/icon/${(branchData?.type && mainBranchTypes.includes(branchData.type)) ? branchData.type : 'down-arrow'}.svg`} 
                        alt='branch-icon' 
                        onClick={onToggleBranch} 
                    />
                ) : (!isBranchLoading && (
                    <div className='no-branch-icon'></div>
                ))}
                {isBranchLoading && <div className='circle-loader'></div>}
                <p className='branch-name truncate-text' title={branchData.displayName} onClick={() => onClickBranch(branchData)}>{getHighlightedText(branchData.displayName, filterText)}</p>
                {!!(branchOpen && (branchData?.type === 'button')) && (
                    <div className={`branch-button ${onBranchButtonClick ? 'branch-button-selectable' : 'branch-button-unselectable'}`} onClick={onClickBranchButton}>
                        {branchButtonLogo && <img className='branch-button-logo' src={branchButtonLogo} alt='button' />}
                        {branchButtonName && <span className='branch-button-name'>{branchButtonName}</span>}
                    </div>
                )}
            </div>
            {(!!childrens.length && branchOpen) && (
                <div className='branch-childrens'>
                    {childrens.map((childrenData, childrenIndex) => (
                        <Branch
                            {...props}
                            isParent={false}
                            key={childrenData?.id ? (childrenData.id + + childrenIndex + branchDepth) : (childrenIndex + branchDepth)}
                            selectable={selectable}
                            branchData={childrenData}
                            onBranchSelect={onBranchSelect}
                            selectedBranches={selectedBranches}
                            parentSelectable={parentSelectable}
                            filterText={filterText}
                            branchDepth={(branchDepth + 1)}
                            unSelectableBranches={unSelectableBranches}
                            branchButtonName={branchButtonName}
                            branchButtonLogo={branchButtonLogo}
                            onBranchButtonClick={onBranchButtonClick}
                            loadData={loadData}
                            searchMode={searchMode}
                            maxVisibleBranches={maxVisibleBranches}
                            defaultOpenBranches={defaultOpenBranches}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default Tree;

function filterTree(treeData, filterText) {
    const deepCopyArr = JSON.parse(JSON.stringify(treeData))
    function filterArray(arr) {
        return arr.filter(node => {
            if (node.displayName.toLowerCase().includes(filterText.toLowerCase())) {
                return true;
            }
            if (node.children) {
                const filteredChildren = filterArray(node.children, filterText);
                if (filteredChildren.length > 0) {
                    node.children = filteredChildren;
                    return true;
                }
            }
            return false;
        });
    }

    const filteredTree = filterArray(deepCopyArr)

    return filteredTree
}
