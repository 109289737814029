import React, { cloneElement, useCallback, useMemo } from 'react'
import ConditionalWrapper from '../conditional-wrapper';

const TableHeader = ({ columns, config, sortBy, sortDirection, sortData, resizeHandler, isAdditionalHeader }) => {
    return (
        <tr className={`${isAdditionalHeader ? "additional-header" : ""}`}>
            {columns.map((column) => (
                <TableHeaderData column={column} config={config} sortBy={sortBy} sortDirection={sortDirection} sortData={sortData} resizeHandler={resizeHandler} key={column.id} isAdditionalHeader={isAdditionalHeader} />
            ))}
        </tr>
    )
}

const TableHeaderData = ({ column, config, sortBy, sortDirection, sortData, resizeHandler, isAdditionalHeader }) => {
    const sortable = useMemo(() => (column.sortable === undefined) ? config.sortable : column.sortable, [config, column])
    const resizable = useMemo(() => (column.resizable === undefined) ? config.resizable : column.resizable, [config, column])
    const expandable = useMemo(() => (column.expandable === undefined) ? config.expandable : column.expandable, [config, column])
    const sorted = useMemo(() => sortBy === column.id, [sortBy, column])

    const handleTableHeadCellClick = useCallback(() => {
        if (sortable) {
            sortData(column.id);
        }
    }, [sortable, sortData, column])


    return (
        <th
            className={`${column.leftFreezed ? "freezed left-freezed" : (column.rightFreezed ? "freezed right-freezed" : "")} ${sortable ? "sortable" : ""} column-id-${column.id} ${column.className || ""}`}
            onClick={handleTableHeadCellClick}
        >
            <ConditionalWrapper condition={expandable} Wrapper={<FlexDataComponentHeaderWrapper />}>
                {
                    isAdditionalHeader ? (<>{column.renderAdditionalHeader}</>) : (column.render ? cloneElement(column.render, { tableData: { isHeader: true, column } }) : (<>{column.title}</>))
                }
            </ConditionalWrapper>

            {
                (sorted && (
                    <img
                        src={`/images/icon/${(sortDirection === "asc") ? "top-arrow.png" : "down-arrow.svg"}`}
                        alt="sort"
                        className="sort-icon"
                    />
                ))
            }

            {resizable && (
                <span
                    className="resize-handle"
                    onMouseDown={resizeHandler(column)}
                >
                    &#x2194;
                </span>
            )}
        </th>
    )
}

const FlexDataComponentHeaderWrapper = ({ children }) => {
    return (
        <div className="items-row">
            {children}
        </div>
    )
}

export default TableHeader