import React from 'react'
import CustomAnchor from '../custom-anchor'

const NoteInfo = ({
    note = "",
    withInfoIcon = false,
    anchorText,
    handleAnchorClick
}) => {
    return (
        <div className='bimmatch-note-info'>
            {withInfoIcon && <img src='/images/icon/info-light.svg' className='info-icon' alt='info' />}
            <p>{note}</p>
            {anchorText && <CustomAnchor onClick={handleAnchorClick}>{anchorText}</CustomAnchor>}
        </div>
    )
}

export default NoteInfo