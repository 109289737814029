export const EVENTS = {
  REVIT_PLACE_OBJECT: "Revit - Place Object",
  REVIT_PLACE_OBJECT_ORG_LIB: "Revit - Place Object(Org Item)",
  REVIT_PRODUCT_APPLY: "Revit - Product Apply",
  REVIT_PRODUCT_APPLY_ORG_LIB: "Revit - Product Apply(Org Item)",
  REVIT_PRODUCT_VIEW: "Revit - Product View",
  WEB_PRODUCT_VIEW_IN_PROJECT: "Web - Product View in Project",
  WEB_PRODUCT_VIEW_IN_CATALOG: "Web - Product View in Catalog",
  WEB_DOWNLOAD_PRODUCT: "Web - Download Product",
  WEB_PRODUCT_APPLY: "Web - Product Apply",
};

export const PROPERTY_KEYS = {
  PROJECT_ID: "projectID",
  PRODUCT_ID: "productID",
  USER_ID: "userID",
  BRAND: "brand",
  ORGANIZATION_ID: "organizationID",
};

export const DISPLAY_NAME_EVENTS = {
  [EVENTS.WEB_PRODUCT_APPLY]: "Applied products",
  [EVENTS.WEB_PRODUCT_VIEW_IN_PROJECT]: "Viewed products",
};
