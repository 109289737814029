import React, { useState, useCallback, useEffect, forwardRef, useMemo } from 'react'
import { bimmatchOrgID, deepClone, fieldValidation, getSectionCustomId } from '../../utils'
import Accordion from '../accordion'
import FormFields from '../form-fields'

function structuringDefaultData(formData, defaultFormValues) {
  let defaultFormData = {}
  const validationData = {}
  const allFieldsData = {}
  const allFields = formData.map(section => section.fields).flat()

  allFields.forEach(fieldData => {
    const { id: fieldId, type, subType, validations, label } = fieldData

    const allValues = {}
    const defaultData = {
      id: fieldId,
      type,
      subType
    }

    if (subType === 'multiSelectionWithTree') {
      defaultData.multiple = fieldData?.validations?.multiple
    }

    if (defaultFormValues && defaultFormValues.length) {
      const formValuesField = defaultFormValues.find(field => field.id === fieldId)
      if (formValuesField?.value) allValues.fieldValue = formValuesField.value
      if (formValuesField?.values) allValues.fieldValues = formValuesField.values
    }

    const { fieldValue, fieldValues } = allValues

    const primaryData = { ...defaultData, label }
    if (validations) primaryData.validations = validations
    validationData[fieldId] = {...primaryData, displaySection: fieldData.displaySection}
    allFieldsData[fieldId] = primaryData

    if (fieldValue || fieldValues) {
      switch (subType) {
        case 'checkbox':
            defaultFormData[fieldId] = {
              ...defaultData,
              value: (typeof fieldValue === 'boolean' ? fieldValue : (fieldValue === 'true' ? true : (fieldValue === 'false' ? false : undefined))) ?? false
            }
          break
  
        case 'toggle':
          defaultFormData[fieldId] = {
            ...defaultData,
            value: fieldValue ?? fieldData?.options[0].id
          }
          break
  
        case 'image':
          // TODO need to handle for this
          break
  
        default:
            defaultFormData[fieldId] = {
              ...defaultData,
              value: fieldValue || fieldValues
            }
          break
      }
    }
  })

  return { defaultFormData, validationData, allFieldsData }
}

const FormGroup = forwardRef(({ formData, onSubmit, uploadId, onChangeStatusOfFileType, onClickSectionAddButton, onClickSectionManageButton, defaultFormValues, formValues, onChange, onError, showFieldSettings, showAppliedSettings, hideAccordionSettings, onClickAccordion, defaultAccordianData, onClickSettings, makeDefaultSettingsAsSettingsValue = false, fieldSettingsValue = {}, onFieldDelete, onValidationCheck, formId, noValuesFieldsHideInSections }, ref) => {

  const [submittedData, setSubmittedData] = useState(undefined)
  const [formValidationData, setFormValidationData] = useState(undefined)
  const [formAllFieldsData, setFormAllFieldsData] = useState(undefined)
  const [errorFields, setErrorFields] = useState(null)

  const formValuesOrDefalultValues = useMemo(() => formValues || defaultFormValues, [formValues, defaultFormValues])

  const checkingValidation = useCallback((fields) => {
    let validatedData = []
    let validationErrorFields = []

    fields.forEach(field => {
      let currentFieldFormValidationData = formValidationData[field.id]
      let currentFieldData = formAllFieldsData[field.id]
      let currentFieldDataForValue = submittedData?.[field.id]
      let fielValidationRules = currentFieldFormValidationData?.validations ? deepClone(currentFieldFormValidationData?.validations) : {}

      let fieldValue = currentFieldDataForValue ? currentFieldDataForValue.value : undefined

      // putting proper types for validation
      if (field?.subType === 'text' || field?.subType === 'textarea') {
        fielValidationRules.type = 'string'
      } else if (field?.type === 'select' && field?.subType !== 'select') {
        fielValidationRules.type = 'select'
        fieldValue = fieldValue || []

      } else {
        fielValidationRules.type = field.subType
      }

      if (field?.subType === 'select' || (field?.validations?.multiple === false)) {
        // giving string value of single select as array for validation
        fieldValue = fieldValue ? [fieldValue] : []
      }

      const { isValid, message = '' } = fieldValidation(fielValidationRules, fieldValue, field?.label)
      if (!isValid) {
        validationErrorFields.push({ ...currentFieldData, message, displaySection: currentFieldFormValidationData.displaySection, formId: formId })
      } else {
        //if error has been removed then removing the field from error array if found
        const indexOfErrorField = validationErrorFields.findIndex(errorField => errorField.id === field.id)
        if (indexOfErrorField > -1) {
          validationErrorFields.splice(indexOfErrorField, 1)
        }
      }
      validatedData.push(isValid)
    })

    const validationErrorObjectFormat = validationErrorFields.reduce((obj, data) => Object.assign(obj, { [data.id]: data }), {})
    setErrorFields(validationErrorObjectFormat)
    const isFormValid = !validatedData.includes(false)
    const finalErrorData = { isFormValid, errorFields: validationErrorFields }
    onValidationCheck && onValidationCheck(finalErrorData)
    return finalErrorData
  }, [formValidationData, formAllFieldsData, submittedData, onValidationCheck, formId])

  useEffect(() => {
    if (formData && formData.length) {
      const { defaultFormData, validationData, allFieldsData } = structuringDefaultData(formData, formValuesOrDefalultValues)
      setSubmittedData({ ...defaultFormData })
      setFormValidationData({ ...validationData })
      setFormAllFieldsData({ ...allFieldsData })
    }

  }, [formData, formValuesOrDefalultValues])

  // checking for validation on every form data change
  useEffect(() => {
    if (formValidationData && formAllFieldsData && submittedData) {
      const valuesOfSubmittedData = Object.values(formValidationData)
      checkingValidation(valuesOfSubmittedData)
    }
  }, [checkingValidation, formAllFieldsData, formValidationData, submittedData])

  const onClickHeadButton = useCallback((sectionId, sectionData, buttonType) => {
    if (buttonType === 'add') {
      onClickSectionAddButton && onClickSectionAddButton(sectionId, sectionData)
    } else if (buttonType === 'visibility') {
      onClickSectionManageButton && onClickSectionManageButton(sectionId, sectionData)
    }
  }, [onClickSectionAddButton, onClickSectionManageButton])

  const onChangeField = useCallback((changedData, fieldData) => {
    const updatedData = deepClone(changedData)
    const { id: fieldId, type, subType } = fieldData
    const fieldValue = changedData[fieldId]
    
    // if field value not found
    // removing it from form data if exists
    if (!fieldValue) {
      if (submittedData[fieldId]) {
        const cloneOfFormData = deepClone(submittedData)
        delete cloneOfFormData[fieldId]
        setSubmittedData({ ...cloneOfFormData })
        onChange && onChange(cloneOfFormData, fieldData)
      }
      return
    }

    updatedData[fieldId] = {
      id: fieldId,
      type,
      subType,
      value: fieldValue
    }

    if (subType === 'number') {
      updatedData[fieldId].unit = changedData.unit
      delete updatedData.unit
    } else if (subType === 'multiSelectionWithTree') { 
      updatedData[fieldId].multiple = fieldData?.validations?.multiple
    }
    
    const newData = submittedData ? { ...submittedData, ...updatedData } : { ...updatedData }
    onChange && onChange(newData, updatedData)
    setSubmittedData(newData)
  }, [submittedData, onChange])



  const handleOnSubmit = useCallback(() => {
    const valuesOfSubmittedData = Object.values(formValidationData)
    const { isFormValid, errorFields: validationErrorFields } = checkingValidation(valuesOfSubmittedData)
    if (isFormValid) {
      if (errorFields) setErrorFields(null)
      onSubmit && onSubmit(submittedData)
    } else {
      onError && onError(validationErrorFields)
    }

  }, [submittedData, checkingValidation, onSubmit, onError, formValidationData, errorFields])

  // on submit of form
  useEffect(() => {
    if (ref.current) {
      ref.current.onclick = () => {
        handleOnSubmit(submittedData)
      }
    }
  }, [ref, handleOnSubmit, submittedData])

  const getFieldValue = useCallback(fieldData => {
    let finalDefaultValue = null
    if (formValuesOrDefalultValues && formValuesOrDefalultValues.length && formAllFieldsData && Object.keys(formAllFieldsData)?.length) {
      const formValuesField = formValuesOrDefalultValues.find(field => field.id === fieldData.id)
      finalDefaultValue = formValuesField?.value || formValuesField?.values || null

      // To remove when boolean gets support in backend
      if (formValuesField) {
        const subTypeCurrentField = formAllFieldsData[formValuesField.id]
        if (subTypeCurrentField && subTypeCurrentField.subType === 'checkbox') {
          finalDefaultValue = (typeof finalDefaultValue === 'boolean' ? finalDefaultValue : (finalDefaultValue === 'true' ? true : (finalDefaultValue === 'false' ? false : undefined))) ?? false
        }
      }
    }
    return finalDefaultValue
  }, [formValuesOrDefalultValues, formAllFieldsData])

  const onClickAccordionHead = useCallback((accordianId, status) => {
    onClickAccordion && onClickAccordion({ [accordianId]: status })
  }, [onClickAccordion])

  const getPropsForValueOrDefaultValue = useCallback(fieldData => {
    // if we are not getting formValues then it will take defaultFieldValue always which is what we need in case of normal form defaultValue
    const keyForProp = formValues && fieldData.type !== 'file' ? 'value' : 'defaultFieldValue'
    return { [keyForProp]: getFieldValue(fieldData) }
  }, [formValues, getFieldValue])

  const filterFieldsWithNoValues = useCallback(sectionData => {
    let filteredFields = sectionData.fields
    if (noValuesFieldsHideInSections && noValuesFieldsHideInSections.length && noValuesFieldsHideInSections.includes(sectionData.id)) {
      filteredFields = filteredFields.filter(fieldData => !!getFieldValue(fieldData) || fieldData?.settings?.visibility === true)
    } else {
      // hiding fields with visibility settings as false
      filteredFields = filteredFields.filter(fieldData => fieldData?.settings?.visibility !== false)
    }

    return filteredFields
  }, [noValuesFieldsHideInSections, getFieldValue])

  const filterFieldsInSectionWithNoValues = useCallback((formData) => {
    let filteredFieldsWithSections = formData.map(singleSection => {
      const fields = filterFieldsWithNoValues(singleSection)
      return ({...singleSection, fields, disable: !fields?.length})
    })

    return filteredFieldsWithSections
  }, [filterFieldsWithNoValues])

  return (
    <div className='formGroup'>
      <div className='formGroup-container'>
        {!!formData.length &&
          filterFieldsInSectionWithNoValues(formData).map(singleSection => (
            <Accordion
              key={getSectionCustomId(singleSection.id) + singleSection.id}
              id={getSectionCustomId(singleSection.id)}
              title={singleSection.displayName}
              onClickHeadButton={() => onClickHeadButton(singleSection.id, singleSection)}
              hideSettings={hideAccordionSettings}
              onClickAccordion={status => onClickAccordionHead(getSectionCustomId(singleSection.id), status)}
              open={defaultAccordianData[getSectionCustomId(singleSection.id)]}
              // disable={singleSection.disable}
              onClickHeaderAddButton={singleSection?.settings?.addProperty && (() => onClickHeadButton(singleSection.id, singleSection, 'add')) }
              onClickHeaderManageButton={singleSection?.settings?.manageVisibility && (() => onClickHeadButton(singleSection.id, singleSection, 'visibility')) }
            >
              {!!singleSection.fields.length &&
                singleSection.fields.map(field => (
                  <FormFields
                    id={field.id}
                    key={field.id}
                    fieldData={field}
                    label={field.label}
                    uploadId={uploadId}
                    type={field.subType}
                    extraData={field?.extras}
                    validations={field?.validations}
                    isError={!!errorFields?.[field.id]}
                    showFieldSettings={showFieldSettings}
                    defaultFieldSettings={(fieldSettingsValue?.[field.id]?.settings || makeDefaultSettingsAsSettingsValue) ? null : field?.settings}
                    showAppliedSettings={showAppliedSettings}
                    onChangeStatus={onChangeStatusOfFileType}
                    onClickSettings={() => onClickSettings(field)}
                    errorMessage={errorFields?.[field.id]?.message}
                    options={field?.sections || field?.options || []}
                    onChange={changedData => onChangeField(changedData, field)}
                    onFieldDelete={field.organizationId !== bimmatchOrgID && onFieldDelete &&  (() => onFieldDelete(field))}
                    fieldSettingsValue={makeDefaultSettingsAsSettingsValue ? field?.settings || {} : fieldSettingsValue?.[field.id]?.settings}
                    {...getPropsForValueOrDefaultValue(field)}
                  />
                ))}
            </Accordion>
          ))}

      </div>
    </div>
  )
})

export default FormGroup
