import React, { useState, useEffect, useCallback } from "react";

function toastImage(type) {
  switch (type) {
    case "alert":
      return "warn";

    case "success":
      return "emoji-happy-green";

    case "error":
      return "emoji-surprise-red";

    //type info
    default:
      return "info";
  }
}

const Toast = ({ toastList, position = "top-right", autoDeleteTime = 6000 }) => {
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  const deleteToast = useCallback(
    (id) => {
      const listItemIndex = list.findIndex((e) => e.id === id);
      const toastListItem = toastList.findIndex((e) => e.id === id);
      list.splice(listItemIndex, 1);
      toastList.splice(toastListItem, 1);
      setList([...list]);
    },
    [list, toastList]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDeleteTime && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, toastList?.[0]?.duration || autoDeleteTime);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDeleteTime, list, deleteToast]);

  return (
    <div className={`notification-container ${position}`}>
      {list.map((toast, i) => (
        <div
          key={i}
          className={`notification toast ${position} ${
            toast.type ? `notification-${toast.type}` : "notification-success"
          }`}
        >
          <button onClick={() => deleteToast(toast.id)}>&#10005;</button>
          {toast.type !== "alert" && (
            <div className="notification-image">
              <img
                src={`/images/icon/${toastImage(toast.type)}.svg`}
                alt="icon"
              />
            </div>
          )}

          <div>
            <p className="notification-title">{toast.title}</p>
            <p className="notification-message">{toast.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Toast;
