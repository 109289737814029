import React from 'react';

const CicularRing = ({ percentage = 30, children, ringColor = "#000", ringBgColor = "#eee", ringWidth = "2px" }) => {
  
    return (
        <div className="bimmatch-circular-ring-wrapper">
            {children}
            <svg viewBox="0 0 36 36" className="bimmatch-circular-chart">
            <path className="bimmatch-circle-bg"
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                style={{ stroke: ringBgColor, strokeWidth: ringWidth}}
            />
            <path className="bimmatch-circle"
                strokeDasharray={`${percentage}, 100`}
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                style={{stroke: ringColor, strokeWidth: ringWidth}}
            />
            </svg>
        </div>
    );
  };

export default CicularRing;