import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TagSelector from '../tag-selector'
import { useGetFacetsData } from '../../hooks';
import { modelingCategoriesHierarchy } from '../../utils';
import InputText from '../input-text';

const GroupedTagSelector = ({ data = [], onChange, defaultValue, value, hideEmpty = true, withSearch = false, searchPlaceholder = "Search" }) => {
    const isMounted = useRef(false);
    const [searchText, setSearchText] = useState("");
    const [selectedTagsByGroup, setSelectedTagsByGroup] = useState({})
    const { categories: allCategories } = useGetFacetsData();
    const categoriesHeirarchy = useMemo(() => modelingCategoriesHierarchy(allCategories), [allCategories])

    const sanitizedData = useMemo(() => {
        return data.filter(tagSelectorData => tagSelectorData.visible !== false && (!hideEmpty || tagSelectorData?.options?.length)).sort((a, b) => {
            const orderA = a?.displayOrder ?? Infinity;
            const orderB = b?.displayOrder ?? Infinity;
            return orderA - orderB;
        });
    }, [data, hideEmpty])

    const handleTagChange = useCallback((id) => (newSelectedTags, tagData, isSelected) => {
        const newSelectedTagsByGroup = { ...selectedTagsByGroup }
        newSelectedTagsByGroup[id] = newSelectedTags
        setSelectedTagsByGroup(newSelectedTagsByGroup)
        if (onChange) {
            onChange(newSelectedTagsByGroup, tagData, isSelected, id)
        }
    }, [selectedTagsByGroup, onChange])

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            if (defaultValue) {
                setSelectedTagsByGroup(defaultValue);
            }
        }
        if (value) {
            setSelectedTagsByGroup(value);
        }
    }, [defaultValue, value])

    const getOptions = useCallback((tagSelectorData) => {
        if (tagSelectorData.id === "categories" && !tagSelectorData.options) {
            return categoriesHeirarchy
        }
        return (tagSelectorData?.options || [])
    }, [categoriesHeirarchy])

    const onSearch = useCallback((value) => {
        setSearchText(value)
    }, [])

    const handleSearchIconClick = useCallback(() => {
        if (searchText) {
            setSearchText("")
        }
    }, [searchText])

    return (
        <div className='bimmatch-grouped-tag-selector'>
            <div>
                {withSearch && (
                    <InputText
                        placeholder={searchPlaceholder}
                        hideLabel
                        onChange={onSearch}
                        value={searchText}
                        iconSrc={`/images/icon/${searchText ? "close.svg" : "search-black.svg"}`}
                        onIconClick={handleSearchIconClick}
                    />
                )}
            </div>
            {sanitizedData.map((tagSelectorData) => (
                <TagSelector
                    key={tagSelectorData.id}
                    id={tagSelectorData.id}
                    options={getOptions(tagSelectorData)}
                    className={`${tagSelectorData.id}-tag-selector`}
                    onChange={handleTagChange(tagSelectorData.id)}
                    value={selectedTagsByGroup[tagSelectorData.id]}
                    searchInput={searchText}
                    hideEmpty={hideEmpty}
                    {...tagSelectorData}
                />
            ))}
        </div>
    )
}

export default GroupedTagSelector;