export const PERMISSIONS = {
  PROJECT_VIEW: "project-view",
  PROJECT_EDIT: "project-edit",
  CREATE_PROJECT: "create-project",
  PRODUCT_EDIT:'product-edit',
  TEAM_VIEW: "team-view",
  ORG_LIB_VIEW: "org-lib-view",
  ANALYTICS_VIEW: "analytics-view",
  ORGANIZATION_ACCOUNT_VIEW: "organization-account-view",
  PROJECT_ORG_LIB_VIEW: "project-org-lib-view", //handling visibility of org lib section in project tab of project page
  CREATE_PROJECT_BUTTON_VIEW: "create-project-button-view", //handling visiblility of create project button
  GENERATE_BOM: "generate-bom", // handling generate bom button access
  INVITE_MEMBER_TO_PROJECT: "invite-member-to-project", // has access to invite member to project
  CONNECT_TO_ACC: "connect-to-acc", // has access to link org with acc account
  SPECS_VIEW: "specs-view", // specs tab access // TODO SPECS to remove
};

export const FEATURES = {
  PROJECT_VIEW: "project-view",
  CREATE_PROJECT: "create-project",
  ANALYTICS_VIEW: "analytics-view",
};
