import React, { createContext, useContext, useRef, useCallback } from 'react';
import { SCROLL_REFERENCE_KEYS } from '../../constants';

const ScrollContext = createContext();

const ScrollProvider = ({ children }) => {
    const refs = useRef(new Map());

    const registerRef = useCallback((name, ref) => {
        if (ref) {
            refs.current.set(name, ref);
        } else {
            refs.current.delete(name);
        }
    }, []);

    const scrollToElement = useCallback((name, selector, offset = 0) => {
        const container = refs.current.get(name);

        if (name === SCROLL_REFERENCE_KEYS.page) {
            const element = document.querySelector(selector);
            if (element) {
                const elementTop = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: elementTop - offset,
                    behavior: 'smooth',
                });
            }
        } else if (container) {
            const element = document.querySelector(selector);
            if (element) {
                const elementTop = element.offsetTop;
                container.scrollTo({
                    top: elementTop - offset,
                    behavior: 'smooth',
                });
            }
        }
    }, []);

    return (
        <ScrollContext.Provider value={{ registerRef, scrollToElement }}>
            {children}
        </ScrollContext.Provider>
    );
};

export default ScrollProvider;

export const useScrollContext = () => {
    return useContext(ScrollContext);
};