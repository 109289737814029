import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import LabelBox from "../label-box";

function Switch(props) {
  const uuid = useMemo(() => uuidv4(), []);
  const {
    value = null,
    defaultValue = null,
    onChange,
    label,
    hideLabel,
    infoPlacement,
    info,
  } = props;

  const [checked, setChecked] = useState(false);
  const defaultValueStatus = useRef(false)

  useEffect(() => {
    if (value !== null) {
      setChecked(value);
    }
  }, [value]);

  // for defaultValue
  useEffect(() => {
    if ((value === null) && !defaultValueStatus.current && (defaultValue !== null)) {
      setChecked(defaultValue)
    }
}, [defaultValue, value])

  const onSwitchClick = useCallback(() => {
    const updatedValue = !checked
    setChecked(updatedValue)
    if (onChange) {
      onChange(updatedValue);
    }
  }, [onChange, checked]);

  return (
    <div className="bimmatch-switch">
      {!hideLabel && (
        <LabelBox label={label} infoPlacement={infoPlacement} info={info} />
      )}
      <label htmlFor={uuid} className="switchCtrl">
        <input
          id={uuid}
          type="checkbox"
          checked={checked}
          disabled={false}
          readOnly={true}
        />
        <span className="toggle" onClick={onSwitchClick}></span>
      </label>
      {/* <label htmlFor="switch-btn" className="title">
        Checkbox as Switch
      </label> */}
    </div>
  );
}

export default React.memo(Switch);
