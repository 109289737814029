import React, { useCallback, useEffect, useRef, useState } from "react";

const Tooltip = ({
  children,
  content = "",
  placement = "top",
  hideArrow = false,
  className,
  autoWidth = false
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const childRef = useRef(null);
  const tooltipRef = useRef(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [arrowPosition, setArrowPosition] = useState({ top: 0, left: 0 });

  const showTooltip = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hideTooltip = useCallback(() => {
    setIsVisible(false);
  }, []);

  const updatePosition = useCallback(() => {
    if (!childRef.current || !tooltipRef.current) {
      return;
    }
    const childRect = childRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    if ((childRect.top < 0) || (childRect.left < 0) || (childRect.top > viewportHeight) || (childRect.left > viewportWidth)) {
      setIsVisible(false);
      return;
    }

    let top = 0, left = 0, arrowTop = 0, arrowLeft = 0;

    switch (placement) {
      case "top":
        top = childRect.top - tooltipRect.height - 5;
        // + window.scrollY
        left = childRect.left + childRect.width / 2 - tooltipRect.width / 2;
        // window.scrollX +
        arrowTop = tooltipRect.height;
        arrowLeft = tooltipRect.width / 2 - 5; // Adjust arrow position
        break;
      case "bottom":
        top = childRect.bottom + 5;
        // + window.scrollY
        left = childRect.left + childRect.width / 2 - tooltipRect.width / 2;
        // window.scrollX +
        arrowTop = -10;
        arrowLeft = tooltipRect.width / 2 - 5; // Adjust arrow position

        break;
      case "left":
        top = childRect.top + childRect.height / 2 - tooltipRect.height / 2;
        // window.scrollY +
        left = childRect.left - tooltipRect.width - 5;
        // + window.scrollX
        arrowTop = tooltipRect.height / 2 - 5;
        arrowLeft = tooltipRect.width; // Adjust arrow position

        break;
      case "right":
        top = childRect.top + childRect.height / 2 - tooltipRect.height / 2;
        // window.scrollY +
        left = childRect.right + 5;
        // + window.scrollX
        arrowTop = tooltipRect.height / 2 - 5; // Adjust arrow position
        arrowLeft = -10;
        break;
      default:
        break;
    }

    // Check for overflow
    if (top < 0) {
      top = 0;
      arrowTop = childRect.top + childRect.height / 2;
    } else if (top + tooltipRect.height > viewportHeight) {
      top = viewportHeight - tooltipRect.height;
      arrowTop = childRect.top - top + childRect.height / 2;
    }

    if (left < 0) {
      left = 0;
      arrowLeft = childRect.left + childRect.width / 2;
    } else if (left + tooltipRect.width > viewportWidth) {
      left = viewportWidth - tooltipRect.width;
      arrowLeft = childRect.left + childRect.width / 2;
    }

    setTooltipPosition({ top, left });
    setArrowPosition({ top: arrowTop, left: arrowLeft });
  }, [placement]);

  useEffect(() => {
    if (isVisible) {
      updatePosition();
    }
  }, [isVisible, updatePosition]);

  return (
    <div
      ref={childRef}
      className={`bimmatch-tooltip ${className || ""}`}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {content && isVisible && (
        <div
          ref={tooltipRef}
          className={`tooltip-content tooltip-content-${placement} ${autoWidth ? "auto-width-content" : ""}`}
          onClick={(e) => e.stopPropagation()}
          style={{
            position: "fixed",
            left: `${Math.floor(+tooltipPosition.left)}px`,
            top: `${Math.floor(+tooltipPosition.top)}px`,
          }}
        >
          {content}
          {!hideArrow && (
            <span
              className="tooltip-arrow"
              style={{
                left: `${Math.floor(+arrowPosition.left)}px`,
                top: `${Math.floor(+arrowPosition.top)}px`,
              }}
            ></span>
          )}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
