import React, { useCallback, useState } from 'react';
import { AutoComplete } from '../../common/map';
import InputNumber from '../input-number';
import InputText from '../input-text';
import LabelBox from '../label-box';


const CreateDistributor = ({ onChange, removable, onRemove, onSelectLocation, returnIndex }) => {

    const [addressData, setAddressData] = useState({})
    const [distributorData, setDistributorData] = useState({ name: '', email: "", address: {} })
    
    const onChangeData = useCallback((type, value) => {
        const updatedData = { ...distributorData, [type]: value }
        setDistributorData(updatedData)
        onChange && onChange(updatedData, { [type]: value })
    }, [distributorData, onChange])

    const onSelectAddress = useCallback(address => {
        onChangeData('address', address)
        setAddressData(address)

        // currentIndex being used in case of multiple distributor
        const currentIndex = returnIndex ? returnIndex().index :  null
        onSelectLocation && onSelectLocation(address.location, currentIndex)
    }, [onChangeData, onSelectLocation, returnIndex])

    const onChangeAddressInput = useCallback(input => {
        if (!input && addressData) {
            onSelectAddress(null)
        }
    }, [addressData, onSelectAddress])

    const onClickRemove = useCallback(() => {
        onRemove && onRemove()
        const currentIndex = returnIndex ? returnIndex().index :  null
        onSelectLocation && onSelectLocation(null, currentIndex)
    }, [onRemove, returnIndex, onSelectLocation]) 

    return (
        <div className='create-distributor'>
            <div className='create-distributor-container'>
                <div className='create-distributor-main'>
                    {removable && <img className='remove-distributor' src={'/images/icon/trash.svg'} alt='delete' onClick={onClickRemove} />}
                    <div className='name-email'>
                        <InputText label={'Distributor Name'} validations={{ required: true }} required={true} onChange={value => onChangeData('name', value)} showValidationMessage={true} />
                        <InputText label={'Email'} required={true} validations={{ required: true, email: true }} onChange={value => onChangeData('email', value)} showValidationMessage={true} applyLowerCase />
                    </div>
                    <div className='phone-address'>
                        <div className='address-content'>
                            <LabelBox label='Location' required={true} />
                            <AutoComplete
                                type="text"
                                name="location"
                                className={'address-input'}
                                placeholder="Search"
                                onChangeInput={onChangeAddressInput}
                                onSelect={onSelectAddress}
                            />
                        </div>
                        <InputNumber subType={'phone'} label={'Phone number'} onChange={value => onChangeData('phone', value)} showValidationMessage={true} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateDistributor;