export const imageType = `
  displayName
  location
  type
  ETag
  Key
  Bucket
`;

export const countryType = `
  long_name
  short_name
`;

export const mapLocationType = `
  lat
  lng
`;

export const geloLocationyType = `
  ${mapLocationType}
  placeid
  types
  addressComponents {
    long_name
    short_name
    types
}`;

export const generalResponse = `
  status
  message
`;