import { useState, useEffect } from 'react';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '../utils';

const useLocalStorage = ({key, intialValue, expiryTime}) => {
    const [storedValue, setStoredValue] = useState(() => {
     try {
        return getLocalStorageWithExpiry(key) || intialValue
     } catch (error) {
        return intialValue || null
     }   
    })

    useEffect(() => {
        try {
            setLocalStorageWithExpiry(key, storedValue, expiryTime)
         } catch (error) {
            console.error('Error accessing localstorage - ' + key , error);
         } 
    }, [key, storedValue, expiryTime])

    return [storedValue, setStoredValue]
}

export default useLocalStorage


